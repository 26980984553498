import React from "react";
import { Dialog } from "@headlessui/react";
import { SettingsIcon } from "./Icons";

const EmptyStateContent = ({ onScheduleEditor }) => (
  <div className="p-6">
    <div className="flex justify-center mb-2">
      <div className="w-16 h-16 bg-blue-100 dark:bg-gray-600 dark:text-white rounded-full flex items-center justify-center">
        <SettingsIcon />
      </div>
    </div>

    <div className="text-center mb-8">
      <Dialog.Title className="text-2xl font-semibold text-gray-900 dark:text-gray-300 mb-2">
        Set Up Your Schedule
      </Dialog.Title>
      <p className="text-gray-600 dark:text-gray-500 mb-8">
        Create your scheduling preferences to start booking meetings with
        candidates.
      </p>
    </div>

    <button
      onClick={onScheduleEditor}
      className="w-full flex flex-col items-center p-6 border-2 border-warmgray-100 dark:border-darkGray rounded-xl hover:border-blue-500 hover:bg-blue-50 dark:hover:bg-gray-600 transition-all group"
    >
      <div className="flex items-center space-x-2 mb-2">
        <div className="text-lg font-semibold dark:text-gray-300">
          Scheduler Editor
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="w-5 h-5 text-blue-500"
        >
          <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
          <polyline points="15 3 21 3 21 9" />
          <line x1="10" y1="14" x2="21" y2="3" />
        </svg>
      </div>
      <p className="text-sm text-gray-600 text-center dark:text-warmgray-400">
        Configure your availability and scheduling preferences
      </p>
    </button>
  </div>
);

export default EmptyStateContent;
