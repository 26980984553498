import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { darkmode } from "../../../store/authSlice";

// Desktop Application Card
export const DesktopApplicationCard = React.forwardRef(
  ({ application, isSelected, onClick }, ref) => {
    const { seeker, status, interview, created_at } = application;
    const mode = useSelector(darkmode);

    return (
      <div
        ref={ref}
        onClick={onClick}
        className={`border bg-white dark:bg-[#1c1c1d] shadow-md rounded-xl p-4 flex items-center transition-all hover:shadow-lg cursor-pointer mb-3
        ${
          isSelected
            ? "border-primary-500 dark:border-gray-500"
            : "border-warmgray-300 dark:border-darkGray"
        }`}
      >
        <div className="mr-4">
          {seeker?.photo ? (
            <div className="w-12 h-12">
              <img
                alt={`${seeker?.first_name}'s profile`}
                src={seeker?.photo?.thumb_url}
                className="w-full h-full rounded-full object-cover border-2 border-purple-100"
              />
            </div>
          ) : (
            <div className="h-12 w-12 bg-blue-100 rounded-full flex items-center justify-center flex-shrink-0 text-lg">
              {seeker?.first_name[0]?.toUpperCase()}
            </div>
          )}
        </div>

        <div className="flex-grow min-w-0 mr-4">
          <div className="flex items-center space-x-2">
            <h3 className="text-primary-500 dark:text-gray-200 font-bold truncate text-lg">
              {seeker?.first_name} {seeker?.last_name}
            </h3>
          </div>
          <div className="flex gap-2">
            {seeker?.city && seeker?.state && (
              <div className="flex items-center text-gray-900 dark:text-gray-500 text-sm">
                <i className="fas fa-map-marker-alt text-[#BC74A9] dark:text-gray-500 text-xs mr-2" />
                <span className="truncate">
                  {`${seeker?.city}, ${seeker?.state}`}
                </span>
              </div>
            )}
            {status === "applied" && interview === "interview scheduled" && (
              <svg
                className="w-5 h-5"
                width="25"
                height="29"
                viewBox="0 0 25 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.48214 1.33929C8.48214 0.597098 7.88504 0 7.14286 0C6.40067 0 5.80357 0.597098 5.80357 1.33929V3.57143H3.57143C1.60156 3.57143 0 5.17299 0 7.14286V8.03571V10.7143V25C0 26.9699 1.60156 28.5714 3.57143 28.5714H21.4286C23.3984 28.5714 25 26.9699 25 25V10.7143V8.03571V7.14286C25 5.17299 23.3984 3.57143 21.4286 3.57143H19.1964V1.33929C19.1964 0.597098 18.5993 0 17.8571 0C17.115 0 16.5179 0.597098 16.5179 1.33929V3.57143H8.48214V1.33929ZM2.67857 10.7143H7.14286V13.8393H2.67857V10.7143ZM2.67857 16.5179H7.14286V20.0893H2.67857V16.5179ZM9.82143 16.5179H15.1786V20.0893H9.82143V16.5179ZM17.8571 16.5179H22.3214V20.0893H17.8571V16.5179ZM22.3214 13.8393H17.8571V10.7143H22.3214V13.8393ZM22.3214 22.7679V25C22.3214 25.4911 21.9196 25.8929 21.4286 25.8929H17.8571V22.7679H22.3214ZM15.1786 22.7679V25.8929H9.82143V22.7679H15.1786ZM7.14286 22.7679V25.8929H3.57143C3.08036 25.8929 2.67857 25.4911 2.67857 25V22.7679H7.14286ZM15.1786 13.8393H9.82143V10.7143H15.1786V13.8393Z"
                  fill={mode ? "#594A9E" : "#A9A9A9"}
                />
              </svg>
            )}
          </div>
          <p className="text-gray-600 dark:text-warmgray-300 text-xs mt-1 xl:block 2xl:hidden">
            {moment(created_at).format("MMM D, YYYY, h:mm A")}
          </p>
        </div>

        <div className="text-right xl:hidden 2xl:block">
          <p className="text-gray-600 dark:text-gray-300 text-xs whitespace-nowrap">
            {moment(created_at).format("MMM D, YYYY")}
          </p>
          <p className="text-gray-400 dark:text-gray-500 text-xs mt-1">
            {moment(created_at).format("h:mm A")}
          </p>
        </div>
      </div>
    );
  }
);

// Mobile Application Card
export const MobileApplicationCard = ({ application, onClick }) => {
  const mode = useSelector(darkmode);
  return (
    <div
      key={application.id}
      onClick={onClick}
      className="border bg-white dark:bg-[#1c1c1d] shadow-md rounded-xl p-4 flex items-center transition-all hover:shadow-lg cursor-pointer border-warmgray-300 dark:border-darkGray"
    >
      <div className="mr-4">
        {application?.seeker?.photo ? (
          <img
            alt={`${application?.seeker?.first_name}'s profile`}
            src={application?.seeker?.photo?.thumb_url}
            className="h-12 w-12 rounded-full object-cover border-2 border-purple-100"
          />
        ) : (
          <div className="h-12 w-12 bg-blue-100 rounded-full flex items-center justify-center flex-shrink-0 text-lg">
            {application?.seeker?.first_name[0]?.toUpperCase()}
          </div>
        )}
      </div>

      <div className="flex-grow min-w-0 mr-4">
        <div className="flex items-center space-x-2">
          <h3 className="text-primary-500 dark:text-gray-200 font-bold truncate text-lg">
            {application?.seeker?.first_name} {application?.seeker?.last_name}
          </h3>
        </div>
        <div className="flex gap-2">
          {application?.seeker?.city && application?.seeker?.state && (
            <div className="flex items-center text-gray-900 dark:text-gray-500 text-xs my-auto">
              <i className="fas fa-map-marker-alt text-[#BC74A9] dark:text-gray-500 text-xs mr-2" />
              <span className="truncate">
                {`${application?.seeker?.city}, ${application?.seeker?.state}`}
              </span>
            </div>
          )}
          {application?.status === "applied" &&
            application?.interview === "interview scheduled" && (
              <svg
                className="w-4 h-4 md:w-5 md:h-5 my-auto"
                width="25"
                height="25"
                viewBox="0 0 25 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.48214 1.33929C8.48214 0.597098 7.88504 0 7.14286 0C6.40067 0 5.80357 0.597098 5.80357 1.33929V3.57143H3.57143C1.60156 3.57143 0 5.17299 0 7.14286V8.03571V10.7143V25C0 26.9699 1.60156 28.5714 3.57143 28.5714H21.4286C23.3984 28.5714 25 26.9699 25 25V10.7143V8.03571V7.14286C25 5.17299 23.3984 3.57143 21.4286 3.57143H19.1964V1.33929C19.1964 0.597098 18.5993 0 17.8571 0C17.115 0 16.5179 0.597098 16.5179 1.33929V3.57143H8.48214V1.33929ZM2.67857 10.7143H7.14286V13.8393H2.67857V10.7143ZM2.67857 16.5179H7.14286V20.0893H2.67857V16.5179ZM9.82143 16.5179H15.1786V20.0893H9.82143V16.5179ZM17.8571 16.5179H22.3214V20.0893H17.8571V16.5179ZM22.3214 13.8393H17.8571V10.7143H22.3214V13.8393ZM22.3214 22.7679V25C22.3214 25.4911 21.9196 25.8929 21.4286 25.8929H17.8571V22.7679H22.3214ZM15.1786 22.7679V25.8929H9.82143V22.7679H15.1786ZM7.14286 22.7679V25.8929H3.57143C3.08036 25.8929 2.67857 25.4911 2.67857 25V22.7679H7.14286ZM15.1786 13.8393H9.82143V10.7143H15.1786V13.8393Z"
                  fill={mode ? "#594A9E" : "#A9A9A9"}
                />
              </svg>
            )}
        </div>
      </div>

      <div className="text-right">
        <p className="text-gray-600 dark:text-gray-300 text-sm whitespace-nowrap">
          {moment(application?.created_at).format("MMM D, YYYY")}
        </p>
        <p className="text-gray-400 dark:text-gray-500 text-xs mt-1">
          {moment(application?.created_at).format("h:mm A")}
        </p>
      </div>
    </div>
  );
};
