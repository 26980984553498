import React from "react";
import { useNavigate } from "react-router-dom";

const IntergrationCard = ({
  name,
  imageSrc,
  description,
  buttonText,
  disabledButton,
  className,
  path,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={
        `relative rounded-md border border-purple-600 dark:border-warmgray-600 dark:bg-[#242526] flex flex-col items-stretch text-center pb-4 px-2 md:px-4 ` +
        className
      }
    >
      <div className="flex justify-center">
        <img src={imageSrc} alt="icon" className={`h-8 md:h-12 my-4`} />
      </div>

      <div className="flex items-center justify-center min-h-24 my-auto py-2 dark:text-warmgray-400">
        <p>{description}</p>
      </div>

      <div className="flex pt-6 w-full mt-auto">
        <div className="w-full flex items-center justify-center">
          <button
            className={`py-2 w-auto cursor-pointer rounded-full px-6 flex items-center justify-center text-sm font-bold shadow-md 
            transition-all disabled:opacity-50 hover:opacity-50 whitespace-nowrap ${
              name === "contactUs"
                ? "bg-black dark:bg-darkGray"
                : "bg-primary-500 dark:bg-darkGray"
            } text-white`}
            disabled={!disabledButton}
            onClick={() => navigate(path)}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntergrationCard;
