import React from "react";
import { DashboardLayout } from "../../../components/organisms/Layout";
import {
  Shifts7DarkLargeIcon,
  Shifts7LightLargeIcon,
  linkIcon,
} from "../../../assets/images";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { darkmode } from "../../../store/authSlice";

const Shifts7 = () => {
  const mode = useSelector(darkmode);
  const handleLink7Shift = () => {};

  return (
    <DashboardLayout>
      <p className="font-extrabold mb-2 text-2xl text-left dark:text-gray-300">
        7Shifts Connect
      </p>
      <div className="w-full max-w-5xl mx-auto text-center">
        <div className="flex justify-center mt-6">
          <img
            src={mode ? Shifts7LightLargeIcon : Shifts7DarkLargeIcon}
            alt="icon"
            className="h-12 md:h-16"
          />
        </div>
        <p className="mb-4 mt-8 md:mt-12 dark:text-warmgray-300">
          7shifts is the complete team management platform for restaurants.
          7shifts provides tools that help restaurateurs make more profitable
          decisions, improve team retention, and get operations in order.
          7shifts is made for everyone — from FOH to BOH — and is trusted by
          over 40,000 restaurants and 1 million restaurant pros.
        </p>
        <p className="mb-8 md:mb-12 dark:text-warmgray-400">
          Don't have a 7Shifts account?
          <span className="">
            <Link
              // to="/login"
              className={`underline text-indigo-600 dark:text-gray-300 hover:text-indigo-500 ml-1`}
            >
              Create one now!
            </Link>
          </span>
        </p>
        <p className="my-8 text-lg lg:text-2xl font-bold dark:text-gray-300">
          Connect your 7 Shifts account to HeyHire
        </p>
        <div className="flex justify-center">
          <button
            className={`px-6 border dark:border-warmgray-500 mr-1 mb-2 flex items-center justify-center bg-primary-500 dark:bg-darkGray text-white transition-all hover:opacity-50
                 text-center font-bold whitespace-nowrap text-ellipsis rounded-full py-2`}
            onClick={() => handleLink7Shift()}
          >
            <img src={linkIcon} alt="logo" className="mr-2 my-auto w-6 h-6" />
            <span className="my-auto font-extrabold">Link 7Shifts</span>
          </button>
        </div>
        <p className="mt-2 font-bold text-sm">
          You will be redirected to 7Shifts.com to allow HeyHire access.
        </p>
      </div>
    </DashboardLayout>
  );
};

export default Shifts7;
