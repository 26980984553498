import { calenderIcon } from "../../../assets/images";

const AvailabilityTable = ({ mode, availability }) => {
  const availabilityData = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const timeSlots = ["Morning", "Afternoon", "Evening", "Night"];

  const getAvailabilityForDay = (day) => {
    const dayAvailability = availability?.find((item) => item?.day === day);
    return dayAvailability ? dayAvailability?.available : [];
  };

  return (
    <div className="rounded-xl border-2 shadow-md border-gray-300 dark:border-darkGray my-6">
      <div className="py-2 px-4 flex">
        {mode ? (
          <img
            src={calenderIcon}
            alt="calender-logo"
            className="mr-2 w-5 h-5 my-auto"
          />
        ) : (
          <i className="fas fa-calendar mr-2 text-primary-500 dark:text-gray-500 text-lg my-auto" />
        )}
        <span className="font-bold text-lg my-auto dark:text-gray-300">
          Availability
        </span>
      </div>
      <div className="pb-4 px-4 pt-2">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-darkGray pb-10">
            <thead className="bg-[#f6f6f6]">
              <tr>
                {availabilityData.map((day) => (
                  <th
                    key={day}
                    scope="col"
                    className="font-bold border border-gray-300 dark:border-darkGray dark:bg-[#2C2D30] dark:text-gray-500 p-2 min-w-10 lg:min-w-20"
                  >
                    <span className="block 2xl:hidden text-xs">
                      {day.slice(0, 3)}
                    </span>
                    <span className="hidden 2xl:block">{day}</span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-[#242526] divide-gray-200 dark:divide-darkGray">
              {timeSlots.map((slot) => (
                <tr key={slot}>
                  {availabilityData.map((day) => (
                    <td
                      key={day}
                      className="p-1 lg:p-2 border border-gray-300 dark:border-darkGray"
                    >
                      <div className="tbl-cell text-center text-[9px] lg:text-[12px] dark:text-warmgray-400">
                        {getAvailabilityForDay(day)?.includes(slot) ? slot : ""}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AvailabilityTable;
