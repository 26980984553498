import React from "react";
import { Dialog } from "@headlessui/react";
import { CalendarIcon, ClockIcon } from "./Icons";

const ScheduleMeeting = ({ onShareSchedule, onSetDateTime }) => {
  return (
    <div className="p-6">
      <div className="flex justify-center mb-2">
        <div className="w-16 h-16 bg-blue-100 dark:bg-gray-600 dark:text-white rounded-full flex items-center justify-center">
          <CalendarIcon />
        </div>
      </div>

      <div className="text-center mb-8">
        <Dialog.Title className="text-2xl font-semibold text-gray-900 dark:text-gray-300 mb-2">
          Schedule a interview
        </Dialog.Title>
        <p className="text-gray-600 dark:text-gray-500">
          Scheduling a interview will automate reminder texts to the candidate
          and to you.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <button
          onClick={onShareSchedule}
          className="flex flex-col items-center p-6 border-2 border-warmgray-100 dark:border-darkGray rounded-xl hover:border-blue-500 hover:bg-blue-50 dark:hover:bg-gray-600 transition-all group"
        >
          <div className="text-blue-600 dark:text-gray-500 mb-3 group-hover:text-blue-700 dark:group-hover:text-white">
            <CalendarIcon />
          </div>
          <div className="text-lg font-semibold mb-2 dark:text-gray-300">
            Share your schedule
          </div>
          <p className="text-sm text-gray-600 text-center dark:text-warmgray-400">
            Let others pick a time that works best for them
          </p>
        </button>

        <button
          onClick={onSetDateTime}
          className="flex flex-col items-center p-6 border-2 border-warmgray-100 dark:border-darkGray rounded-xl hover:border-blue-500 hover:bg-blue-50 dark:hover:bg-gray-600 transition-all group"
        >
          <div className="text-blue-600 dark:text-gray-500 mb-3 group-hover:text-blue-700 dark:group-hover:text-white">
            <ClockIcon />
          </div>
          <div className="text-lg font-semibold mb-2 dark:text-gray-300">
            Set Date and Time
          </div>
          <p className="text-sm text-gray-600 text-center dark:text-warmgray-400">
            Choose a specific time for your meeting
          </p>
        </button>
      </div>
    </div>
  );
};

export default ScheduleMeeting;
