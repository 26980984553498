import React, { useState, useEffect, useRef, useMemo } from "react";
import { Col, Row } from "antd";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  darkmode,
  minimisedPersonsArray,
  mobileView,
  selectUser,
  selectedConversation,
  selectedPersons,
  setChatApplicant,
  setDeleteChat,
  setJobApplicantTitle,
  setJobPositionTitle,
  setMinimizedPersons,
  setMobileView,
  setSelectedConversation,
  setSelectedPersons,
  setVisiblePersons,
  visiblePersonsArray,
} from "../../../store/authSlice";
import { DashboardLayout } from "../../../components/organisms/Layout";
import Application from "../../../api/Application";
import JobPosition from "../../../api/JobPosition";
import { encodeIds, decodeIds } from "../../../utils";
import { useTranslation } from "react-i18next";
import JobPositionSelect from "../../../components/atoms/Selector/JobPositionSelect";
import JobSeeker from "../../../api/JobSeeker";
import ApplicantInfo from "../ViewApplicant/ApplicantInfo";
import ApplicationCardSkeleton from "../../../components/Loading/ApplicationCardSkeleton";
import NotesModal from "../../../components/modals/NotesModal";
import NewConfirmationModal from "../../../components/modals/NewConfirmationModal";
import SchedulingOptionsModal from "../Scheduler/SchedulingOptionsModal";
import {
  DesktopApplicationCard,
  MobileApplicationCard,
} from "../../../components/molecules/Application/ApplicationCard";
import SelectedApplication from "../ViewApplicant/SelectedApplication";
import Chat from "../../../api/Chat";
import ApplicationLoadingSkeleton from "../../../components/Loading/ApplicationLoadingSkeleton";
import { PositionHeader } from "../ViewApplicant/PositionHeader";
import RecruitmentTools from "../ViewApplicant/RecruitmentTools";

const ViewPositions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mode = useSelector(darkmode);
  const user = useSelector(selectUser);
  const isMobileView = useSelector(mobileView);
  const selectConversation = useSelector(selectedConversation);
  const visiblePersons = useSelector(visiblePersonsArray);
  const minimizedPersons = useSelector(minimisedPersonsArray);
  const selectedPerson = useSelector(selectedPersons);
  const location = useLocation();
  const applicationsListRef = useRef(null);
  const applicationRefsMap = useRef({});
  const previousPositionRef = useRef(null);

  // State Management
  const [loading, setLoading] = useState(true);
  const [loadingApplications, setLoadingApplications] = useState(false);
  // First, update the URL parsing at the top of the component
  const urlParams = location.pathname.split("/");
  const positionId = decodeIds(urlParams[3]);
  const seekerId = urlParams.length > 4 ? decodeIds(urlParams[4]) : null;
  const [activeSegment, setActiveSegment] = useState("Applicants");
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [jobPositions, setJobPositions] = useState([]);
  const [active, setActive] = useState([]);
  const [interviews, setInterviews] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [canceled, setCanceled] = useState([]);
  const [hired, setHired] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [applicant, setApplicant] = useState(null);
  const [loadingApplicant, setLoadingApplicant] = useState(false);
  const [apiAvailabilityData, setApiAvailabilityData] = useState([]);
  const [application, setApplication] = useState({});
  const [activityLogs, setActivityLogs] = useState([]);
  const [showApplicantDetail, setShowApplicantDetail] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [type, setType] = useState("");
  const [isSchedulingOpen, setIsSchedulingOpen] = useState(false);
  const [loadingEvent, setLoadingEvent] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isApplicationTransitioning, setIsApplicationTransitioning] =
    useState(false);

  // Mobile View Check
  useEffect(() => {
    const checkMobileView = () => {
      dispatch(setMobileView(window.innerWidth < 1280));
    };

    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => window.removeEventListener("resize", checkMobileView);
  }, []);

  // Filtered Applications
  const filteredApplications = useMemo(() => {
    let filtered = [];
    switch (activeSegment) {
      case "Applicants":
        filtered = active;
        break;
      case "Interviews":
        filtered = interviews;
        break;
      case "Hired":
        filtered = hired.map((app) => ({ ...app, isHired: true }));
        break;
      case "Declined":
        filtered = [...canceled, ...rejected];
        break;
      default:
        filtered = [];
    }
    return filtered?.sort(
      (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
    );
  }, [activeSegment, active, interviews, hired, canceled, rejected]);

  // URL Management
  const updateURL = (position, application) => {
    if (!position?.value?.id) return;
    const basePath = "/business/applicant";
    const encodedPositionId = encodeIds(position.value.id.toString());
    let newUrl = `${basePath}/${encodedPositionId}`;
    if (application?.seeker?.id) {
      const encodedSeekerId = encodeIds(application.seeker.id.toString());
      newUrl += `/${encodedSeekerId}`;
    }
    window.history.pushState({}, "", newUrl);
  };

  // Activity Logs
  const getActivity = async (id) => {
    try {
      const response = await Application.getApplicationLogs(id);
      setActivityLogs(response.data.data);
    } catch (error) {
      console.error("Error fetching activity logs:", error);
    }
  };

  // Application Details
  const loadApplicationDetails = async (positionId, seekerId) => {
    try {
      const app = await Application.getByPositionId(positionId);
      const appls = app.data.data;
      const filteredApplication = appls?.filter(
        (r) => r.job_seeker_id === Number(seekerId) && r.status === "applied"
      );
      if (filteredApplication?.length > 0) {
        const selectedApplication = filteredApplication[0];
        setApplication(selectedApplication);
        getActivity(selectedApplication.id);
      }
    } catch (error) {
      console.error("Error loading application details:", error);
      toast.error("Failed to load application details. Please try again.");
    }
  };

  // Availability Data
  const loadAvailability = async (id) => {
    try {
      const data = await Application.getByIdForAvailability(id);
      setApiAvailabilityData(data?.data?.availability || []);
    } catch (error) {
      console.error("Error fetching availability:", error);
    }
  };

  // Application Data Loading
  const loadApplicationData = async (application) => {
    if (!application?.seeker?.id) return;

    try {
      setLoadingApplicant(true);
      const job = await JobSeeker.getById(application?.seeker?.id);
      setApplicant(job?.data?.data);
      loadAvailability(application?.seeker?.id);
      loadApplicationDetails(
        selectedPosition?.value?.id,
        application?.seeker?.id
      );
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/subscription");
      } else {
        toast.error("Failed to load applicant details. Please try again.");
      }
    } finally {
      setLoadingApplicant(false);
    }
  };

  // Job Positions Loading
  const loadJobPositions = async () => {
    try {
      setLoading(true);
      const response = await JobPosition.getAll();
      const sortedJobs = response.data.data.filter(
        (j) => j?.archived_at == null && j?.deleted_at == null
      );
      setJobPositions(sortedJobs);

      let defaultOption;
      const matchedPosition = sortedJobs.find(
        (job) => job.id === Number(positionId)
      );

      if (matchedPosition) {
        defaultOption = {
          value: matchedPosition,
          label: matchedPosition?.title,
        };
      } else if (sortedJobs.length > 0) {
        defaultOption = {
          value: sortedJobs[0],
          label: sortedJobs[0]?.title,
        };
      }

      if (defaultOption) {
        setSelectedPosition(defaultOption);
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/subscription");
      } else {
        toast.error(t("common.something-went-wrong-please-try-again"));
      }
    } finally {
      setLoading(false);
    }
  };

  // Employees Loading
  const loadEmployees = async (positionId) => {
    try {
      const tempUsers = await JobPosition.getHiredEmployees(positionId);
      setHired(tempUsers.data.data);
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/subscription");
      } else {
        toast.error("Failed to load Hired Employees. Please try again.");
      }
    }
  };

  // Applications Loading
  const loadApplications = async (positionId) => {
    if (!positionId)
      return { active: [], interview: [], canceled: [], rejected: [] };

    try {
      setLoadingApplications(true);
      document.title = `HeyHire Portal-Viewing-${selectedPosition?.label}`;
      const response = await Application.getByPositionId(positionId);
      const active = response?.data?.data?.filter(
        (r) =>
          r.status === "applied" &&
          (r.interview === "" || r.interview === "canceled")
      );
      const interview = response?.data?.data?.filter(
        (r) =>
          r.status === "applied" &&
          (r.interview === "interview scheduled" ||
            r.interview === "interview shared")
      );
      const cancel = response?.data?.data?.filter(
        (r) => r.status === "canceled"
      );
      const reject = response?.data?.data?.filter(
        (r) => r.status === "rejected"
      );

      const sortActive = active?.sort(
        (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
      );

      const sortInterview = interview?.sort(
        (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
      );

      setActive(sortActive || []);
      setInterviews(sortInterview || []);
      setRejected(reject || []);
      setCanceled(cancel || []);

      return {
        active: sortActive || [],
        interview: sortInterview || [],
        canceled: cancel || [],
        rejected: reject || [],
      };
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/subscription");
      } else {
        toast.error("Failed to load job. Please try again.");
      }
      return { active: [], interview: [], canceled: [], rejected: [] };
    } finally {
      setLoadingApplications(false);
    }
  };

  const handleApplicationSelection = async (
    application,
    isPositionChange = false
  ) => {
    if (!application || (isApplicationTransitioning && !isPositionChange))
      return;

    try {
      setIsApplicationTransitioning(true);
      setSelectedApplication(application);

      await loadApplicationData(application);

      scrollToSelectedApplication(application);
      updateURL(selectedPosition, application);

      if (selectedPosition) {
        dispatch(setJobPositionTitle(selectedPosition));
      }
      dispatch(
        setJobApplicantTitle(
          application?.seeker?.first_name + " " + application?.seeker?.last_name
        )
      );
    } catch (error) {
      console.error("Error selecting application:", error);
      toast.error("Failed to load application details");
    } finally {
      setIsApplicationTransitioning(false);
    }
  };

  // Navigation
  const navigateApplication = async (direction) => {
    const currentApplications = filteredApplications;
    if (!selectedApplication || currentApplications.length === 0) return;

    const currentIndex = currentApplications.findIndex(
      (app) => app.id === selectedApplication.id
    );

    let newIndex;
    if (direction === "next") {
      newIndex = (currentIndex + 1) % currentApplications.length;
    } else {
      newIndex =
        (currentIndex - 1 + currentApplications.length) %
        currentApplications.length;
    }

    const newSelectedApplication = currentApplications[newIndex];
    await handleApplicationSelection(newSelectedApplication);
  };

  // Scrolling
  const scrollToSelectedApplication = (application) => {
    if (!applicationRefsMap.current[application?.id]) return;

    const selectedApplicationElement =
      applicationRefsMap.current[application?.id];
    const containerElement = applicationsListRef.current;

    if (selectedApplicationElement && containerElement) {
      const containerRect = containerElement?.getBoundingClientRect();
      const elementRect = selectedApplicationElement?.getBoundingClientRect();

      const scrollTop =
        selectedApplicationElement.offsetTop -
        containerElement.offsetTop -
        (containerRect.height / 2 - elementRect.height / 2);

      containerElement.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  // First Application Selection
  const selectFirstApplication = async (applications) => {
    if (applications.length > 0) {
      const firstApplication = applications[0];
      await handleApplicationSelection(firstApplication);
    } else {
      setSelectedApplication(null);
      setApplicant(null);
    }
  };

  // Initial Load
  useEffect(() => {
    if (user) {
      loadJobPositions();
    }
  }, [user]);

  useEffect(() => {
    const loadInitialData = async () => {
      if (selectedPosition && isInitialLoad) {
        try {
          setLoadingApplications(true);

          // Load applications and employees
          const [applicationsResponse, employeesResponse] = await Promise.all([
            Application.getByPositionId(selectedPosition?.value?.id),
            loadEmployees(selectedPosition?.value?.id),
          ]);

          // Process and sort applications
          const applications = applicationsResponse?.data?.data || [];

          // Categorize applications
          const active = applications
            .filter(
              (r) =>
                r.status === "applied" &&
                (r.interview === "" || r.interview === "canceled")
            )
            .sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));

          const interview = applications
            .filter(
              (r) =>
                r.status === "applied" &&
                (r.interview === "interview scheduled" ||
                  r.interview === "interview shared")
            )
            .sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));

          const cancel = applications.filter((r) => r.status === "canceled");
          const reject = applications.filter((r) => r.status === "rejected");

          // Update state
          setActive(active);
          setInterviews(interview);
          setRejected(reject);
          setCanceled(cancel);

          // If we have a seekerId, find and select the specific application
          if (seekerId) {
            const allApplications = [
              ...active,
              ...interview,
              ...hired,
              ...cancel,
              ...reject,
            ];
            const targetApplication = allApplications.find(
              (app) => app.seeker?.id === Number(seekerId)
            );

            if (targetApplication) {
              // Force detail view for mobile
              if (isMobileView) {
                setShowApplicantDetail(true);
              }

              // Select the application and load its details
              await handleApplicationSelection(targetApplication);

              // Set the correct segment (mainly for desktop view)
              if (
                targetApplication.interview === "interview scheduled" ||
                targetApplication.interview === "interview shared"
              ) {
                setActiveSegment("Interviews");
              } else if (
                targetApplication.status === "canceled" ||
                targetApplication.status === "rejected"
              ) {
                setActiveSegment("Declined");
              } else if (hired.some((h) => h.seeker?.id === Number(seekerId))) {
                setActiveSegment("Hired");
              } else {
                setActiveSegment("Applicants");
              }
            } else {
              toast.error("Application not found");
              // Reset URL if application not found
              const basePath = "/business/applicant";
              const encodedPositionId = encodeIds(
                selectedPosition?.value?.id?.toString()
              );
              navigate(`${basePath}/${encodedPositionId}`);
            }
          } else {
            // No seekerId - select first available application
            if (active.length > 0) {
              setActiveSegment("Applicants");
              await handleApplicationSelection(active[0]);
            } else if (interview.length > 0) {
              setActiveSegment("Interviews");
              await handleApplicationSelection(interview[0]);
            } else if (hired.length > 0) {
              setActiveSegment("Hired");
              await handleApplicationSelection(hired[0]);
            } else if (cancel.length > 0 || reject.length > 0) {
              setActiveSegment("Declined");
              await handleApplicationSelection(cancel[0] || reject[0]);
            }
          }
        } catch (error) {
          console.error("Error in initial load:", error);
          toast.error("Failed to load data. Please try again.");
        } finally {
          setLoadingApplications(false);
          setIsInitialLoad(false);
          previousPositionRef.current = selectedPosition?.value?.id;
        }
      }
    };

    loadInitialData();
  }, [selectedPosition, isInitialLoad]);

  useEffect(() => {
    const handlePositionChange = async () => {
      if (
        !selectedPosition?.value?.id ||
        selectedPosition?.value?.id === previousPositionRef.current
      ) {
        return;
      }

      try {
        setLoadingApplications(true);
        dispatch(setJobApplicantTitle(null));
        // Load all applications and employees first
        const response = await Application.getByPositionId(
          selectedPosition.value.id
        );
        await loadEmployees(selectedPosition.value.id);
        // Process applications by status
        const active = response?.data?.data?.filter(
          (r) =>
            r.status === "applied" &&
            (r.interview === "" || r.interview === "canceled")
        );
        const interview = response?.data?.data?.filter(
          (r) =>
            r.status === "applied" &&
            (r.interview === "interview scheduled" ||
              r.interview === "interview shared")
        );
        const cancel = response?.data?.data?.filter(
          (r) => r.status === "canceled"
        );
        const reject = response?.data?.data?.filter(
          (r) => r.status === "rejected"
        );

        // Sort applications by creation date
        const sortActive = active?.sort(
          (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
        );
        const sortInterview = interview?.sort(
          (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
        );

        // Update application states
        setActive(sortActive || []);
        setInterviews(sortInterview || []);
        setRejected(reject || []);
        setCanceled(cancel || []);

        // Find first segment with applications and set it as active
        let newSegment = null;
        let applicationToSelect = null;

        if (sortActive?.length > 0) {
          newSegment = "Applicants";
          applicationToSelect = sortActive[0];
        } else if (sortInterview?.length > 0) {
          newSegment = "Interviews";
          applicationToSelect = sortInterview[0];
        } else if (hired?.length > 0) {
          newSegment = "Hired";
          applicationToSelect = hired[0];
        } else if (cancel?.length > 0 || reject?.length > 0) {
          newSegment = "Declined";
          applicationToSelect = cancel[0] || reject[0];
        }

        if (newSegment) {
          setActiveSegment(newSegment);
          if (applicationToSelect) {
            await handleApplicationSelection(applicationToSelect, true);
          }
        } else {
          // No applications in any segment
          dispatch(setJobApplicantTitle(null));
          setActiveSegment("Applicants");
          setSelectedApplication(null);
          setApplicant(null);
          updateURL(selectedPosition, null);
        }
      } catch (error) {
        console.error("Error handling position change:", error);
        toast.error("Failed to load position data");

        // Reset states on error
        setActive([]);
        setInterviews([]);
        setRejected([]);
        setCanceled([]);
        setSelectedApplication(null);
        setApplicant(null);
      } finally {
        setLoadingApplications(false);
        previousPositionRef.current = selectedPosition?.value?.id;
      }
    };

    if (selectedPosition && !isInitialLoad) {
      handlePositionChange();
    }
  }, [selectedPosition, hired]);

  useEffect(() => {
    selectFirstApplication(filteredApplications);
  }, [activeSegment, active, interviews, hired, rejected, canceled]);

  // Event Handlers
  const handleChatApplicant = (applicant) => {
    dispatch(setChatApplicant(applicant));
  };

  const handleApplicationClick = (application) => {
    setSelectedApplication(application);
    if (isMobileView) {
      setShowApplicantDetail(true);
    }
  };

  const handleOpenNotesModal = () => {
    setIsNotesModalOpen(true);
  };

  const handleCloseNotesModal = () => {
    setIsNotesModalOpen(false);
  };

  const hireApplicant = async (applicationId) => {
    try {
      setLoadingEvent(true);
      await Application.hireByApplicationId(applicationId);
      toast.success("Applicant Hired successfully");
      setIsModalVisible(false);
      await Promise.all([
        loadApplications(selectedPosition?.value?.id),
        loadEmployees(selectedPosition?.value?.id),
      ]);
      setActiveSegment("Hired");
    } catch (error) {
      setIsModalVisible(false);
      toast.error("an error occurred");
    } finally {
      setLoadingEvent(false);
    }
  };

  const rejectApplication = async (applicant, declineReason) => {
    try {
      setLoadingEvent(true);
      const payload = {
        id: selectedApplication?.id,
        reason: declineReason,
      };
      await Application.rejectByApplicationId(payload);
      toast.success("Applicant Rejected");
      setIsModalVisible(false);
      await Promise.all([
        loadApplications(selectedPosition?.value?.id),
        loadEmployees(selectedPosition?.value?.id),
      ]);
      // if (applicant?.conversation_id) {
      //   deleteChat(applicant?.conversation_id, applicant?.seeker?.id);
      // }
      setActiveSegment("Declined");
    } catch (error) {
      setIsModalVisible(false);
      toast.error("an error occurred");
    } finally {
      setLoadingEvent(false);
    }
  };

  const deleteChat = async (id, jobSeekerID) => {
    try {
      // Call API to delete the chat
      await Chat.deleteConversationByID(id);

      const updatedSelectedConversation = { ...selectConversation };
      for (const key in updatedSelectedConversation) {
        if (updatedSelectedConversation.hasOwnProperty(key)) {
          if (updatedSelectedConversation[key].conversation_id === id) {
            delete updatedSelectedConversation[key];
            break;
          }
        }
      }
      dispatch(setSelectedConversation(updatedSelectedConversation));

      const updatedVisiblePersons = (visiblePersons || [])?.filter(
        (person) => person?.job_seeker_id !== jobSeekerID
      );
      dispatch(setVisiblePersons(updatedVisiblePersons));

      const updatedMinimizedPersons = (minimizedPersons || [])?.filter(
        (person) => person?.job_seeker_id !== jobSeekerID
      );
      dispatch(setMinimizedPersons(updatedMinimizedPersons));

      const updatedSelectedPersons = selectedPerson.filter(
        (person) => person?.job_seeker_id !== jobSeekerID
      );
      dispatch(setSelectedPersons(updatedSelectedPersons));

      dispatch(setDeleteChat(true));
      toast.success("Chat deleted successfully");
    } catch (error) {
      if (error?.response?.status !== 403) {
        console.error("Error deleting chat:", error);
        toast.error("Failed to delete chat. Please try again.");
      }
    }
  };

  const handleHireApplicant = () => {
    setType("hire");
    setIsModalVisible(true);
  };

  const handleRejectApplication = () => {
    setType("decline");
    setIsModalVisible(true);
  };

  const handleInterview = () => {
    setIsSchedulingOpen(true);
  };

  // Render Application Content
  const renderApplicationContent = () => {
    if (isApplicationTransitioning) {
      return (
        <div className="w-full text-center py-4 dark:text-gray-300">
          Loading application...
        </div>
      );
    }

    if (loadingApplicant) {
      return (
        <div className="w-full text-center py-4 dark:text-gray-300">
          Loading applicant details...
        </div>
      );
    }

    return (
      applicant && (
        <ApplicantInfo
          mode={mode}
          applicant={applicant}
          availability={apiAvailabilityData}
          application={application}
          activityLogs={activityLogs}
        />
      )
    );
  };

  // Render Segments
  const renderSegments = () => {
    const segments = [
      { label: "Applicants", count: active.length },
      { label: "Interviews", count: interviews.length },
      { label: "Hired", count: hired.length },
      { label: "Declined", count: rejected.length + canceled.length },
    ];

    return segments.map((segment, index) => (
      <button
        key={segment.label}
        onClick={() => segment.count > 0 && setActiveSegment(segment.label)}
        disabled={segment.count === 0 || isApplicationTransitioning}
        className={`flex flex-1 items-center justify-center py-2 px-4 bg-warmgray-200 dark:bg-darkGray text-gray-900 dark:text-gray-300 text-sm 
          ${index === 3 ? "border-none" : "border-r border-warmgray-400"}
          ${index === 0 && "border-l border-warmgray-400"}
          ${activeSegment === segment.label ? "font-bold" : ""}
          ${segment.count === 0 ? "opacity-50 cursor-not-allowed" : ""}
          ${
            activeSegment === segment.label && segment.count > 0
              ? "font-bold"
              : ""
          }`}
      >
        <span>{segment.label}</span>
        <span className="ml-1 text-xs">({segment.count})</span>
      </button>
    ));
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="flex flex-col h-full">
        {isMobileView ? (
          <div className="flex flex-col h-full">
            {/* Force detail view if seekerId exists, otherwise check showApplicantDetail */}
            {!seekerId && !showApplicantDetail ? (
              // List View
              <>
                {!loading && (
                  <div className="flex items-center justify-center">
                    <div className="mt-4 bg-white dark:bg-[#242526] border border-warmgray-200 dark:border-darkGray px-4 py-2 rounded-xl">
                      <JobPositionSelect
                        jobPositions={jobPositions}
                        selectedPosition={selectedPosition}
                        setSelectedPosition={setSelectedPosition}
                        mode={mode}
                      />
                    </div>
                  </div>
                )}
                <div className="flex whitespace-nowrap overflow-x-auto px-4 py-1.5 rounded-xl border-2 border-warmgray-200 dark:border-darkGray bg-warmgray-200 dark:bg-[#242526] my-3">
                  {renderSegments()}
                </div>
                <div className="flex-1 overflow-y-auto mb-3">
                  {loadingApplications || loading ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                      {Array.from({ length: 5 }).map((_, index) => (
                        <ApplicationCardSkeleton key={index} />
                      ))}
                    </div>
                  ) : filteredApplications?.length === 0 ? (
                    <div>
                      <PositionHeader selectedPosition={selectedPosition} />
                      <div className="mt-2 border border-primary-500 dark:border-darkGray text-gray-900 dark:text-gray-300 p-4 rounded-xl text-sm text-center">
                        Once you have applicants they will appear here
                      </div>
                    </div>
                  ) : (
                    <>
                      <PositionHeader selectedPosition={selectedPosition} />
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        {filteredApplications?.map((application) => (
                          <MobileApplicationCard
                            key={application.id}
                            application={application}
                            onClick={() => handleApplicationClick(application)}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              // Detail View
              <div className="flex-1 overflow-y-auto">
                <div className="flex items-center mb-4">
                  <button
                    onClick={() => {
                      const basePath = "/business/applicant";
                      const encodedPositionId = encodeIds(
                        selectedPosition?.value?.id?.toString()
                      );
                      const newUrl = `${basePath}/${encodedPositionId}`;
                      navigate(newUrl); // Use navigate instead of history.pushState
                      setShowApplicantDetail(false);
                    }}
                    className="font-bold text-primary-500 dark:text-primary-300 hover:text-primary-600 dark:hover:text-primary-400 pr-4 rounded-lg"
                  >
                    ← Back to Applications
                  </button>
                </div>
                {loading || loadingApplications ? (
                  <ApplicationLoadingSkeleton isMobileView={true} />
                ) : (
                  <>
                    <SelectedApplication
                      disabled={
                        isApplicationTransitioning || loadingApplications
                      }
                      selectedApplication={selectedApplication}
                      selectedPosition={selectedPosition}
                      onNavigate={navigateApplication}
                      onMessage={handleChatApplicant}
                      onInterview={handleInterview}
                      onNotes={handleOpenNotesModal}
                      onHire={handleHireApplicant}
                      onReject={handleRejectApplication}
                      isMobileView={true}
                      totalApplications={filteredApplications.length}
                    />
                    {renderApplicationContent()}
                  </>
                )}
              </div>
            )}
          </div>
        ) : (
          <Row gutter={[16, 16]}>
            <Col
              xs={24}
              lg={7}
              className={`${
                filteredApplications?.length === 0
                  ? "h-auto"
                  : "h-[calc(100vh-150px)]"
              } sticky top-0`}
            >
              <div
                className="h-full overflow-y-auto 2xl:pr-2"
                ref={applicationsListRef}
              >
                {loadingApplications || loading ? (
                  Array.from({ length: 5 }).map((_, index) => (
                    <ApplicationCardSkeleton key={index} />
                  ))
                ) : filteredApplications?.length === 0 ? (
                  <div>
                    <PositionHeader selectedPosition={selectedPosition} />
                    <div className="mt-2 border border-primary-500 dark:border-darkGray text-gray-900 dark:text-gray-300 p-4 rounded-xl text-sm text-center">
                      Once you have applicants they will appear here
                    </div>
                  </div>
                ) : (
                  <>
                    <PositionHeader selectedPosition={selectedPosition} />
                    {filteredApplications?.map((application) => (
                      <DesktopApplicationCard
                        key={application.id}
                        application={application}
                        isSelected={selectedApplication?.id === application?.id}
                        onClick={() => handleApplicationSelection(application)}
                        ref={(el) => {
                          applicationRefsMap.current[application.id] = el;
                        }}
                      />
                    ))}
                  </>
                )}
              </div>
            </Col>

            <Col xs={24} lg={17}>
              {loadingApplications || loading ? (
                <ApplicationLoadingSkeleton />
              ) : (
                <>
                  <div className="flex items-center w-full rounded-xl border-2 border-warmgray-200 dark:border-darkGray dark:bg-darkGray mb-6 whitespace-nowrap overflow-x-auto">
                    <div className="flex flex-shrink-0">
                      <JobPositionSelect
                        jobPositions={jobPositions}
                        selectedPosition={selectedPosition}
                        setSelectedPosition={setSelectedPosition}
                        mode={mode}
                      />
                    </div>
                    {renderSegments()}
                  </div>

                  {filteredApplications?.length > 0 && (
                    <>
                      <SelectedApplication
                        disabled={
                          isApplicationTransitioning || loadingApplications
                        }
                        selectedApplication={selectedApplication}
                        selectedPosition={selectedPosition}
                        onNavigate={navigateApplication}
                        onMessage={handleChatApplicant}
                        onInterview={handleInterview}
                        onNotes={handleOpenNotesModal}
                        onHire={handleHireApplicant}
                        onReject={handleRejectApplication}
                        isMobileView={false}
                        totalApplications={filteredApplications.length}
                      />
                      {renderApplicationContent()}
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>
        )}
        {!loadingApplications &&
          !loading &&
          filteredApplications?.length === 0 && <RecruitmentTools />}

        <NotesModal
          isOpen={isNotesModalOpen}
          onClose={handleCloseNotesModal}
          application={selectedApplication}
        />

        <NewConfirmationModal
          open={isModalVisible}
          setOpen={setIsModalVisible}
          type={type}
          onClick={(declineReason) => {
            if (type === "hire") {
              hireApplicant(selectedApplication?.id);
            } else {
              rejectApplication(selectedApplication, declineReason);
            }
          }}
          applicant={selectedApplication?.seeker}
          positionTitle={selectedPosition?.label}
          loading={loadingEvent}
        />

        <SchedulingOptionsModal
          open={isSchedulingOpen}
          setOpen={() => setIsSchedulingOpen(false)}
          applicant={selectedApplication?.seeker}
          positionId={selectedPosition?.value?.id}
        />
      </div>
    </DashboardLayout>
  );
};

export default ViewPositions;
