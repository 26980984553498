import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DashboardLayout } from "../../../components/organisms/Layout";
import { encodeIds } from "../../../utils";
import Table from "../../../components/atoms/Tables/Table";
import { defaultUserImage } from "../../../assets/images";
import moment from "moment";
import User from "../../../api/User";
import { toast } from "react-toastify";
import {
  selectSelectedLocation,
  setInitialChatFetch,
  setMinimizedPersons,
  setSelectedLocation,
  setSelectedPersons,
  setVisiblePersons,
} from "../../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";

const ViewApplicantsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const passedApplicantsData = state?.applicantsData || [];
  const passedSelectedOption = state?.selectedOption || [];
  const selectedLocation = useSelector(selectSelectedLocation);
  const sortConfig = {
    Name: (row) => {
      const firstName = row.props.children[0].props.children[1];
      const lastName = row.props.children[0].props.children[2];
      return String(`${firstName} ${lastName}`).trim().toLowerCase();
    },
    "Position Applied": (row) => String(row.props.children[1].props.children),
  };

  const changeLocation = async (id) => {
    try {
      await User.updateLocation(id);
      toast.success("Location Changed!");
    } catch (error) {
      toast.success("Error Occurred during Location switching. Try Again!");
    }
  };

  const handleApplicantClick = (jobId, applicantId, navigateCondition) => {
    if (passedSelectedOption) {
      dispatch(setSelectedLocation(passedSelectedOption?.value));
      localStorage.setItem(
        "selectedLocation",
        JSON.stringify(passedSelectedOption?.value)
      );
      if (passedSelectedOption?.value?.name !== selectedLocation?.name) {
        changeLocation(passedSelectedOption?.value?.id);
      }
      if (navigateCondition === "applicant") {
        navigate(
          `/business/applicant/${encodeIds(jobId)}/${encodeIds(applicantId)}`
        );
      } else {
        navigate(`/business/applicant/${encodeIds(jobId)}`);
      }
      dispatch(setInitialChatFetch(false));
      dispatch(setVisiblePersons([]));
      dispatch(setMinimizedPersons([]));
      dispatch(setSelectedPersons([]));
    }
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="pt-3 md:pt-6">
        <div className="overflow-x-auto overflow-hidden">
          <Table
            sortConfig={sortConfig}
            mainHeader="Job Applicants"
            headers={
              passedApplicantsData?.length > 0
                ? ["Name", "Position Applied", "Date", "Response Time"]
                : ["No Applicants Yet"]
            }
            data={passedApplicantsData?.map((job, index) => (
              <tr key={index}>
                <td
                  className="flex items-center whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900 dark:text-white hover:underline cursor-pointer"
                  onClick={() =>
                    handleApplicantClick(
                      job?.job_seeker?.job_id,
                      job?.job_seeker?.job_seeker_id,
                      "applicant"
                    )
                  }
                >
                  <img
                    alt=""
                    src={job?.photo?.thumb_url || defaultUserImage}
                    className="h-9 w-9 rounded-full mr-2"
                  />
                  {job?.job_seeker?.first_name} {job?.job_seeker?.last_name}
                </td>
                <td
                  className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900 dark:text-white cursor-pointer"
                  onClick={() =>
                    handleApplicantClick(job?.job_seeker?.job_id, "job")
                  }
                >
                  {job?.job_title}
                </td>
                <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900 dark:text-white">
                  {moment(job?.job_seeker?.created_at).format("MMM DD, YYYY")}
                </td>
                <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900 dark:text-white">
                  {(() => {
                    const responseTime = job?.response_time?.toLowerCase();
                    let tagColor = "bg-green-100 text-green-800";

                    if (
                      responseTime?.includes("year") ||
                      responseTime?.includes("1+ years")
                    ) {
                      tagColor =
                        "text-red-600 dark:text-red-400 bg-red-50 dark:bg-red-500/20";
                    } else if (responseTime?.includes("month")) {
                      tagColor =
                        "text-orange-600 dark:text-orange-400 bg-orange-50 dark:bg-orange-500/20";
                    } else if (responseTime?.includes("day")) {
                      tagColor =
                        "text-yellow-600 dark:text-yellow-400 bg-yellow-50 dark:bg-yellow-500/20";
                    }

                    return (
                      <span
                        className={`px-2 py-1 rounded-full font-medium ${tagColor}`}
                      >
                        {job?.response_time}
                      </span>
                    );
                  })()}
                </td>
              </tr>
            ))}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ViewApplicantsList;
