import axios from "./api";

const base = "/business/dashboard";

const Dashboard = {
  getRecentLogins() {
    return axios.get(`${base}/admin/recent-logins`);
  },
  getRecentLocations() {
    return axios.get(`${base}/admin/recent-locations`);
  },
  getRecentSeekers() {
    return axios.get(`${base}/admin/recent-seekers`);
  },
  getRecentApplications(locationId) {
    const endpoint = locationId
      ? `${base}/admin/recent-applications?location_id=${locationId}`
      : `${base}/admin/recent-applications`;

    return axios.get(endpoint);
  },
};

export default Dashboard;
