import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../../../components/organisms/Layout";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { defaultUserImage, defaultUserImage2 } from "../../../assets/images";
import { useSelector } from "react-redux";
import {
  darkmode,
  selectSelectedLocation,
  selectUser,
  sidebar,
} from "../../../store/authSlice";
import Scheduler from "../../../api/Scheduler";

export default function Interviews() {
  const mode = useSelector(darkmode);
  const user = useSelector(selectUser);
  const openSidebar = useSelector(sidebar);
  const selectedLocation = useSelector(selectSelectedLocation);
  const [activeTab, setActiveTab] = useState("pending");
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState();
  const [calendar, setCalendar] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allInterviews, setAllInterviews] = useState({
    pending: [],
    confirmed: [],
    past: [],
  });

  const tabs = [
    { id: "pending", name: "Pending Interviews" },
    { id: "confirmed", name: "Confirmed Interviews" },
    { id: "past", name: "Past Interviews" },
  ];

  const loadInterviewDetails = async () => {
    try {
      setIsLoading(true);
      const payload = {
        user_id: user?.id,
        company_id: user?.company_id,
        location_id:
          selectedLocation?.name === "Select A Location"
            ? ""
            : selectedLocation?.id,
      };
      const response = await Scheduler.getScheduledInterview(payload);
      // Categorize interviews once
      const currentTime = Math.floor(Date.now() / 1000);
      const categorizedInterviews = {
        pending: [],
        confirmed: [],
        past: [],
      };

      response?.data?.data?.forEach((interview) => {
        const scheduleType =
          interview.schedule_interview.schedule_type === "set";
        const isDeleted = interview.schedule_interview.deleted_at !== null;
        const status = interview.schedule_interview.status;
        const startTime = interview.schedule_interview.start_time;

        if (!isDeleted && scheduleType) {
          if (status === "booked" && startTime > currentTime) {
            categorizedInterviews.confirmed.push(interview);
          }
          if (
            (startTime < currentTime && status === "booked") ||
            interview.schedule_interview.is_interview_complete === 1
          ) {
            categorizedInterviews.past.push(interview);
          }
        } else if (!isDeleted) {
          if (status === "booked" || !status) {
            categorizedInterviews.pending.push(interview);
          }
        }
      });

      // Sort each category
      Object.keys(categorizedInterviews).forEach((key) => {
        categorizedInterviews[key] = sortInterviewsByPastDate(
          categorizedInterviews[key]
        );
      });

      setAllInterviews(categorizedInterviews);

      // Set initial date based on active tab's first interview
      const activeInterviews = categorizedInterviews[activeTab];
      if (activeInterviews.length > 0) {
        const firstInterview =
          activeInterviews[0]?.schedule_interview?.start_time;
        if (firstInterview) {
          const val = getFormattedDate(firstInterview);
          setSelectedDate(val);
          setMonth(val.month);
          setYear(val.year);
        }
      } else {
        const today = new Date();
        setSelectedDate({
          date: today.getDate(),
          month: today.getMonth() + 1,
          year: today.getFullYear(),
        });
      }
    } catch (error) {
      console.error("Error loading interview details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadInterviewDetails();
  }, [selectedLocation]);

  // Handle tab change
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    const activeInterviews = allInterviews[tabId];
    if (activeInterviews.length > 0) {
      const firstInterview =
        activeInterviews[0]?.schedule_interview?.start_time;
      if (firstInterview) {
        const val = getFormattedDate(firstInterview);
        setSelectedDate(val);
        setMonth(val.month);
        setYear(val.year);
      }
    }
  };

  useEffect(() => {
    generateCalendar(month, year);
  }, [month, year]);

  const generateCalendar = (selectedMonth, selectedYear) => {
    const firstDay = new Date(selectedYear, selectedMonth - 1, 1).getDay();
    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
    const weeks = [];
    let currentWeek = new Array(7).fill(null);

    let day = 1;
    for (let i = firstDay; i < 7; i++) {
      currentWeek[i] = day++;
    }
    weeks.push([...currentWeek]);

    while (day <= daysInMonth) {
      currentWeek = new Array(7).fill(null);
      for (let i = 0; i < 7 && day <= daysInMonth; i++) {
        currentWeek[i] = day++;
      }
      weeks.push([...currentWeek]);
    }

    setCalendar(weeks);
  };

  const prevMonth = () => {
    if (month === 1) {
      setMonth(12);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const nextMonth = () => {
    if (month === 12) {
      setMonth(1);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const goToToday = () => {
    const today = new Date();
    setMonth(today.getMonth() + 1);
    setYear(today.getFullYear());
    const datObj = {
      date: today.getDate(),
      month: today.getMonth() + 1,
      year: today.getFullYear(),
    };
    setSelectedDate(datObj);
  };

  const handleSelectedDate = (currDay) => {
    const datObj = {
      date: currDay,
      month: month,
      year: year,
    };
    setSelectedDate(datObj);
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  const getFormattedDate = (timestamp) => {
    const date = new Date(timestamp * 1000);

    return {
      date: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  };

  const sortInterviewsByPastDate = (data) => {
    return data.sort((a, b) => {
      return b.schedule_interview.start_time - a.schedule_interview.start_time;
    });
  };

  const timeStampConvert = (day) => {
    const calObj = {
      day: !!day ? day : 0,
      year: year,
      month: month,
    };
    const currentTabInterviews = allInterviews[activeTab] || [];
    const dateVal = currentTabInterviews?.map((d) =>
      getFormattedDate(d?.schedule_interview?.start_time)
    );
    return dateVal?.some(
      (dateObj) =>
        dateObj?.date === calObj?.day &&
        dateObj?.month === calObj?.month &&
        dateObj?.year === calObj?.year
    );
  };

  const handleScheduleClick = (frmtDate, frmtMnth, frmtYear) => {
    const datObj = {
      date: frmtDate,
      month: frmtMnth,
      year: frmtYear,
    };
    setSelectedDate(datObj);
    setMonth(datObj.month);
    setYear(datObj.year);
  };

  const getActiveTabName = () => {
    return tabs.find((tab) => tab.id === activeTab)?.name || "";
  };

  return (
    <DashboardLayout>
      <div className="max-w-7xl">
        {/* Header Section */}
        <div className="my-4">
          <h1 className="text-xl xl:text-2xl font-bold text-gray-900 dark:text-white">
            Scheduled Interviews
          </h1>
        </div>

        {/* Navigation Tabs */}
        <nav className="mb-4">
          <div className="bg-warmgray-200 dark:bg-gray-600 rounded-lg p-1">
            <div className="flex space-x-1">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => handleTabChange(tab.id)}
                  className={`
                  flex-1 px-4 py-2.5 text-sm rounded-md
                  ${
                    activeTab === tab.id
                      ? "bg-white shadow text-gray-900 dark:bg-darkGray dark:text-warmgray-300"
                      : "text-gray-500 dark:text-warmgray-400 hover:text-gray-900"
                  }
                `}
                >
                  {tab.name}
                </button>
              ))}
            </div>
          </div>
        </nav>

        {/* Calendar Section */}
        <div className="bg-white dark:bg-gray-600 rounded-lg shadow border border-warmgray-200 dark:border-darkGray">
          <div
            className={`p-6 ${
              activeTab !== "pending"
                ? `${
                    openSidebar
                      ? "xl:grid xl:grid-cols-2 xl:divide-x xl:divide-warmgray-300"
                      : "lg:grid lg:grid-cols-2 lg:divide-x lg:divide-warmgray-300"
                  } dark:divide-warmgray-300`
                : ""
            }`}
          >
            {activeTab !== "pending" && (
              <div className={`${openSidebar ? "xl:pr-14" : "lg:pr-14"}`}>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <select
                      value={month}
                      className="appearance-none border-none bg-transparent text-lg font-semibold text-gray-900 dark:text-gray-300"
                      onChange={(e) => setMonth(parseInt(e.target.value))}
                    >
                      {Array.from({ length: 12 }, (_, i) => (
                        <option key={i + 1} value={i + 1}>
                          {new Date(2023, i, 1).toLocaleString("default", {
                            month: "long",
                          })}
                        </option>
                      ))}
                    </select>
                    <select
                      value={year}
                      className="appearance-none border-none bg-transparent text-lg font-semibold text-gray-900 dark:text-gray-300 ml-2"
                      onChange={(e) => setYear(parseInt(e.target.value))}
                    >
                      {Array.from({ length: 10 }, (_, i) => {
                        const yearValue = new Date().getFullYear() - 5 + i;
                        return (
                          <option key={yearValue} value={yearValue}>
                            {yearValue}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="flex items-center space-x-2 overflow-auto">
                    <button
                      onClick={prevMonth}
                      className="p-1.5 hover:bg-gray-100 dark:hover:bg-darkGray rounded"
                    >
                      <ChevronLeftIcon className="h-5 w-5 dark:text-gray-300" />
                    </button>
                    <button
                      onClick={goToToday}
                      className="px-3 py-1.5 text-sm font-medium text-gray-900 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-darkGray rounded"
                    >
                      Today
                    </button>
                    <button
                      onClick={nextMonth}
                      className="p-1.5 hover:bg-gray-100 dark:hover:bg-darkGray rounded"
                    >
                      <ChevronRightIcon className="h-5 w-5 dark:text-gray-300" />
                    </button>
                  </div>
                </div>

                {/* Calendar Grid */}
                <div className="mt-6">
                  <div className="grid grid-cols-7 text-center text-xs text-gray-500">
                    {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                      (day) => (
                        <div key={day} className="py-2">
                          {day}
                        </div>
                      )
                    )}
                  </div>

                  <div className="mt-2 grid grid-cols-7 text-sm">
                    {calendar.map((week, weekIndex) => (
                      <React.Fragment key={weekIndex}>
                        {week.map((day, dayIndex) => (
                          <button
                            key={dayIndex}
                            className={`
                            mx-auto flex h-8 w-8 items-center justify-center rounded-full
                            ${
                              day === selectedDate?.date &&
                              month === selectedDate?.month &&
                              year === selectedDate?.year
                                ? "bg-blue-600 text-white"
                                : timeStampConvert(day)
                                ? "text-blue-600 font-semibold"
                                : "text-gray-900 dark:text-gray-500"
                            }
                          `}
                            onClick={() => day && handleSelectedDate(day)}
                          >
                            {day || ""}
                          </button>
                        ))}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {/* Interview List Section */}
            <section
              className={`${
                activeTab !== "pending"
                  ? `mt-12 ${
                      openSidebar ? "xl:mt-0 xl:pl-14" : "lg:mt-0 lg:pl-14"
                    }`
                  : ""
              } max-h-[50vh] overflow-y-scroll`}
            >
              <h2 className="text-base font-semibold text-gray-900 dark:text-gray-300">
                {getActiveTabName()}
              </h2>
              {isLoading ? (
                <div className="flex justify-center items-center h-40">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-gray-300"></div>
                </div>
              ) : allInterviews[activeTab]?.length > 0 ? (
                <ol className="mt-4 flex flex-col gap-y-1 text-sm/6 text-gray-500">
                  {allInterviews[activeTab]?.map((data, index) => {
                    const frmtDate = getFormattedDate(
                      data?.schedule_interview?.start_time
                    )?.date;
                    const frmtMnth = getFormattedDate(
                      data?.schedule_interview?.start_time
                    )?.month;
                    const frmtYear = getFormattedDate(
                      data?.schedule_interview?.start_time
                    )?.year;

                    return (
                      <li
                        key={index}
                        className={`group flex items-center ${
                          activeTab !== "pending" && "cursor-pointer"
                        } gap-x-4 rounded-xl
                          ${
                            selectedDate?.date === frmtDate &&
                            selectedDate?.month === frmtMnth &&
                            selectedDate?.year === frmtYear
                              ? "bg-warmgray-100 dark:bg-darkGray"
                              : ""
                          } 
                          px-4 py-2 focus-within:bg-gray-100 hover:bg-warmgray-100 dark:hover:bg-darkGray`}
                        onClick={() => {
                          if (activeTab !== "pending")
                            handleScheduleClick(frmtDate, frmtMnth, frmtYear);
                        }}
                      >
                        <img
                          src={
                            (data?.photo?.original_url &&
                              data?.photo?.original_url) ||
                            (!mode ? defaultUserImage2 : defaultUserImage)
                          }
                          alt=""
                          className="size-10 flex-none rounded-full"
                        />
                        <div className="flex-auto">
                          <p className="text-gray-900 dark:text-gray-300">
                            {data?.job_position_details?.title} Interview with{" "}
                            {data?.job_seeker_details?.first_name}{" "}
                            {data?.job_seeker_details?.last_name} from{" "}
                            {data?.schedule_interview?.location_name}
                            {activeTab !== "pending" && (
                              <>
                                {" - "}
                                {new Date(
                                  frmtYear,
                                  frmtMnth - 1,
                                  1
                                ).toLocaleString("default", {
                                  month: "short",
                                })}{" "}
                                {frmtDate}
                              </>
                            )}
                          </p>
                          {activeTab !== "pending" && (
                            <p className="mt-0.5 text-gray-500 dark:text-warmgray-400">
                              {data?.schedule_interview?.start_time &&
                              data?.schedule_interview?.end_time
                                ? `${formatTime(
                                    data?.schedule_interview?.start_time
                                  )} - ${formatTime(
                                    data?.schedule_interview?.end_time
                                  )}`
                                : "Time not set"}
                            </p>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ol>
              ) : (
                <p className="text-gray-500 dark:text-gray-300 text-sm flex justify-start items-center pt-[10px] m-[20px]">
                  No {activeTab === "past" ? "past" : "upcoming"} interviews
                </p>
              )}
            </section>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
