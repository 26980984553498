import React from "react";

const ApplicationCardSkeleton = () => (
  <div className="border border-warmgray-300 dark:border-darkGray bg-white dark:bg-transparent shadow-md rounded-xl p-4 flex items-center transition-all hover:shadow-lg cursor-pointer mb-3 animate-pulse">
    <div className="mr-4">
      <div className="h-12 w-12 bg-gray-300 dark:bg-[#363739] rounded-full" />
    </div>
    <div className="flex-grow min-w-0 mr-4">
      <div className="h-4 bg-gray-300 dark:bg-[#363739] rounded w-3/4 mb-2" />
      <div className="h-3 bg-gray-300 dark:bg-[#363739] rounded w-1/2" />
    </div>
    <div className="text-right">
      <div className="h-3 bg-gray-300 dark:bg-[#363739] rounded w-20 mb-2" />
      <div className="h-2 bg-gray-300 dark:bg-[#363739] rounded w-16" />
    </div>
  </div>
);

export default ApplicationCardSkeleton;
