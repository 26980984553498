import React, { Fragment, useEffect, useState } from "react";
import { NylasScheduling } from "@nylas/react";
import { DashboardLayout } from "../../../components/organisms/Layout";
import { Dialog, Transition } from "@headlessui/react";
import Scheduler from "../../../api/Scheduler";
import { useSelector } from "react-redux";
import { selectSelectedLocation, selectUser } from "../../../store/authSlice";
import { toast } from "react-toastify";

export default function SchedulePage() {
  const [mode, setMode] = useState("");
  const user = useSelector(selectUser);
  const selectedLocation = useSelector(selectSelectedLocation);
  const [reference, setReference] = useState("");
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [bookingDetails, setBookingDetails] = useState({
    configId: null,
    bookingId: null,
  });
  const [schedulerData, setSchedulerData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleBooking = async (event) => {
    try {
      const payload = {
        user_id: user?.id,
        company_id: user?.company_id,
        location_id: selectedLocation?.id,
        job_seeker_id: schedulerData?.job_seeker_id,
        job_position_id: schedulerData?.job_position_id,
        schedule_type: "set",
        schedule_config_id: bookingDetails?.configId,
        booking_id: bookingDetails?.bookingId,
        event_id: event?.detail?.data?.event_id,
        status: event?.detail?.data?.status,
        timeStamp: event?.timeStamp,
        start_time: event?.detail?.data?.start_time,
        end_time: event?.detail?.data?.end_time,
        organizer: JSON.stringify(event?.detail?.data?.organizer),
        guest: JSON.stringify(event?.detail?.data?.guest),
        additional_guests: JSON.stringify(
          event?.detail?.data?.additional_guests
        ),
      };
      await Scheduler.storeBookingData(payload);
      toast.success("Interview time has been set successfully!");
    } catch (error) {
      toast.error("Failed to set interview time.");
      console.error("Booking error:", error);
    }
  };

  const cancelBooking = async (event) => {
    try {
      const payload = {
        user_id: user?.id,
        company_id: user?.company_id,
        location_id: selectedLocation?.id,
        job_seeker_id: schedulerData?.job_seeker_id,
        job_position_id: schedulerData?.job_position_id,
        schedule_type: "set",
        schedule_config_id: bookingDetails?.configId,
        booking_id: bookingDetails?.bookingId,
        status: event?.detail?.action,
        reason: event?.detail?.reason,
        timeStamp: event?.timeStamp,
      };
      await Scheduler.cancelBookingData(payload);
      toast.success("Interview cancelled!");
    } catch (error) {
      toast.error("Failed to cancel interview.");
      console.error("Booking error:", error);
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);

    if (path.includes("/cancel/")) {
      setMode("cancel");
      setReference(path.split("/cancel/")[1]);
    } else if (path.includes("/reschedule/")) {
      setMode("reschedule");
      setReference(path.split("/reschedule/")[1]);
    } else if (path.includes("/preview")) {
      setMode("preview");
      const configId = searchParams.get("config_id");
      setReference(configId || "");
    }
  }, []);

  const fetchSchedulerDetails = async (configId, bookingId) => {
    if (!configId || !bookingId) {
      setError("No Scheduler Found");
      return;
    }

    try {
      setIsLoading(true);
      setError(null);
      const response = await Scheduler.getInterviewByID(configId, bookingId);
      setSchedulerData(response.data.data[0]);
    } catch (err) {
      setError("No Scheduler Found");
      console.error("Error fetching scheduler details:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const schedulingComponent = document.querySelector("nylas-scheduling");

    if (schedulingComponent) {
      schedulingComponent.addEventListener("bookingRefExtracted", async (e) => {
        const { configurationId, bookingId } = e.detail;
        setBookingDetails({
          configId: configurationId,
          bookingId: bookingId,
        });
        await fetchSchedulerDetails(configurationId, bookingId);
      });
    }

    return () => {
      if (schedulingComponent) {
        schedulingComponent.removeEventListener(
          "bookingRefExtracted",
          () => {}
        );
      }
    };
  }, [mode]);

  const renderSchedulingWidget = () => {
    if (!reference) return null;

    switch (mode) {
      case "cancel":
        return (
          <NylasScheduling
            cancelBookingRef={reference}
            eventOverrides={{
              cancelBookingFormSubmitted: async (event) => {
                cancelBooking(event);
              },
            }}
            enableUserFeedback={false}
          />
        );
      case "reschedule":
        return (
          <NylasScheduling
            rescheduleBookingRef={reference}
            eventOverrides={{
              bookedEventInfo: async (event) => {
                handleBooking(event);
              },
              cancelBookingFormSubmitted: async (event) => {
                cancelBooking(event);
              },
            }}
            enableUserFeedback={false}
          />
        );
      case "preview":
        return (
          <NylasScheduling
            configurationId={reference}
            enableUserFeedback={false}
            eventOverrides={{
              timeslotConfirmed: (event) => {
                event.preventDefault();
                setShowPreviewModal(true);
              },
            }}
          />
        );
      default:
        return null;
    }
  };

  const getPageTitle = () => {
    switch (mode) {
      case "cancel":
        return "Cancel Meeting";
      case "reschedule":
        return "Reschedule Meeting";
      case "preview":
        return "Schedule Preview";
      default:
        return "Scheduling";
    }
  };

  return (
    <DashboardLayout>
      <div className="px-4 py-5 sm:p-6">
        <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-300 mb-6">
          {getPageTitle()}
        </h1>
        <div className="relative">
          {error ? (
            <div className="p-4 bg-red-50 rounded-md">
              <p className="text-red-600">{error}</p>
            </div>
          ) : isLoading ? (
            <div className="p-4 bg-gray-50 dark:bg-gray-600 rounded-md">
              <p className="text-gray-600 dark:text-gray-300">
                Loading scheduler details...
              </p>
            </div>
          ) : (
            renderSchedulingWidget()
          )}
        </div>
        <Transition appear show={showPreviewModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={() => setShowPreviewModal(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 mb-4"
                    >
                      Preview Message
                    </Dialog.Title>

                    <div className="mt-2">
                      <p className="text-sm text-gray-600 mt-4">
                        This is a preview of the scheduling interface. To book
                        an interview at this time, please return to the
                        applicant profile and initiate the scheduling process
                        from there.
                      </p>
                    </div>

                    <div className="mt-6">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent bg-primary-500 px-4 py-2 text-sm font-medium text-white hover:bg-primary-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={() => setShowPreviewModal(false)}
                      >
                        Close Preview
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </DashboardLayout>
  );
}
