import React from "react";
import { DashboardLayout } from "../../components/organisms/Layout";
import {
  Shifts7DarkLargeIcon,
  Shifts7LightLargeIcon,
  adpIcon,
  contactUsIcon,
  gustoIcon,
  squareIcon,
  toastIcon,
} from "../../assets/images";
import IntergrationCard from "../../components/IntergrationCard";
import { useSelector } from "react-redux";
import { darkmode } from "../../store/authSlice";

const Intergrations = () => {
  const mode = useSelector(darkmode);
  const data = [
    {
      id: 1,
      name: "7shifts",
      image: mode ? Shifts7LightLargeIcon : Shifts7DarkLargeIcon,
      description:
        "7shifts is the complete team management platform for restaurants. 7shifts provides tools that help restaurateurs make more profitable decisions, improve team retention, and get operations in order. 7shifts is made for everyone — from FOH to BOH — and is trusted by over 40,000 restaurants and 1 million restaurant pros.",
      buttonText: "Configure",
      showButton: true,
      path: "/intergrations/7Shifts",
    },
    {
      id: 2,
      name: "toast",
      image: toastIcon,
      description: "Coming Soon...",
      buttonText: "Configure",
      showButton: false,
      path: "/item2",
    },
    {
      id: 3,
      name: "square",
      image: squareIcon,
      description: "Coming Soon...",
      buttonText: "Configure",
      showButton: false,
      path: "/item3",
    },
    {
      id: 4,
      name: "adp",
      image: adpIcon,
      description: "Coming Soon...",
      buttonText: "Configure",
      showButton: false,
      path: "/item4",
    },
    {
      id: 5,
      name: "gusto",
      image: gustoIcon,
      description: "Coming Soon...",
      buttonText: "Configure",
      showButton: false,
      path: "/item5",
    },
    {
      id: 6,
      name: "contactUs",
      image: contactUsIcon,
      description:
        "Can't find the intergration you're looking? for feel free to request it!",
      buttonText: "Contact Us",
      showButton: true,
      path: "/support",
    },
  ];

  return (
    <DashboardLayout>
      <div className="w-full max-w-7xl">
        <p className="font-bold mb-3 text-2xl text-left dark:text-gray-300">
          Intergrations
        </p>
        <p className="border border-gray-300 dark:border-darkGray dark:text-gray-300 dark:bg-warmgray-800 p-2.5 text-center shadow-sm rounded-xl">
          You can connect your account to the partners listed below to ensure a
          smoother hiring process blurb
        </p>

        <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-6 pt-12 pb-4">
          {data?.map((item) => (
            <IntergrationCard
              key={item.id}
              name={item.name}
              imageSrc={item.image}
              description={item.description}
              buttonText={item.buttonText}
              disabledButton={item.showButton}
              path={item.path}
            />
          ))}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Intergrations;
