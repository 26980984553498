import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import "./ResumeViewer.scss";
import {
  resumeViewerPDFIcon,
  resumeViewerPdfGrayIcon,
  addIcon,
} from "../../../assets/images";
import Application from "../../../api/Application";

const ResumeViewer = ({ applicant, mode, application }) => {
  const [docs, setDocs] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isRequestingResume, setIsRequestingResume] = useState(false);
  const [isResumeRequested, setIsResumeRequested] = useState(false);

  useEffect(() => {
    // Set initial resume requested state based on application prop
    setIsResumeRequested(application?.is_request_resume || false);
  }, [application]);

  useEffect(() => {
    if (applicant && applicant?.file && applicant?.file?.length > 0) {
      let resumeUrl = applicant?.file[0]?.resume_url;
      resumeUrl = resumeUrl?.replace(/([^:])\/\//g, "$1/");

      const fileName = applicant?.file[0]?.file_name;
      const fileType = (fileName?.match(/\.([^.]+)$/) || [
        ,
        "pdf",
      ])[1].toLowerCase();

      const newDocs = [
        {
          uri: resumeUrl,
          fileType,
          fileName,
        },
      ];
      setDocs(newDocs);
      setTimeout(() => {
        toggleSection();
      }, 2000);
      setIsLoading(false);
    } else {
      setDocs([]);
      setIsLoading(false);
    }
  }, [applicant]);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(false);
  }, [mode]);

  const handleRequestResume = async () => {
    // If resume is already requested, don't make the API call
    if (application?.is_request_resume) {
      setIsResumeRequested(true);
      return;
    }

    try {
      setIsRequestingResume(true);
      await Application.requestResume({
        location_id: application?.location_id,
        job_seeker_id: application?.job_seeker_id,
        job_id: application?.job_id,
      });
      setIsResumeRequested(true);
    } catch (error) {
      console.error("Error requesting resume:", error);
    } finally {
      setIsRequestingResume(false);
    }
  };

  const renderButton = () => {
    if (isResumeRequested || application?.is_request_resume) {
      return (
        <button
          disabled
          className="flex justify-center items-center rounded-full bg-gray-400 dark:bg-gray-600 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm"
        >
          Resume Requested
        </button>
      );
    }

    return (
      <button
        onClick={handleRequestResume}
        disabled={isRequestingResume}
        className="flex justify-center items-center rounded-full bg-primary-500 dark:bg-darkGray px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <div className="flex items-center">
          {isRequestingResume ? (
            <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
          ) : (
            <img src={addIcon} alt="add" className="mr-2 my-auto w-4 h-4" />
          )}
          Request Resume
        </div>
      </button>
    );
  };

  return (
    <div className="rounded mt-2 border-2 shadow-md border-gray-300 dark:border-darkGray">
      <dl>
        <div>
          <dt>
            <div className="flex w-full items-start justify-between text-left py-2 px-4">
              <div className="flex">
                <img
                  src={mode ? resumeViewerPDFIcon : resumeViewerPdfGrayIcon}
                  alt="resume-logo"
                  className="mr-2 h-6 my-auto"
                />
                <span className="font-bold text-lg my-auto dark:text-gray-300">
                  Resume
                </span>
              </div>
              <span
                className="ml-6 flex h-7 items-center cursor-pointer dark:text-gray-500"
                onClick={toggleSection}
              >
                {isOpen ? (
                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                ) : (
                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                )}
              </span>
            </div>
          </dt>
          <dd>
            {isLoading ? (
              <div className="p-4 dark:text-gray-500">Loading...</div>
            ) : isOpen ? (
              docs?.length > 0 ? (
                <DocViewer
                  documents={docs}
                  initialActiveDocument={docs[0]}
                  pluginRenderers={DocViewerRenderers}
                  className="custom-doc-viewer"
                  theme={{
                    primary: "rgb(243 244 246)",
                    secondary: "#ffffff",
                    tertiary: "#ffffff",
                    textPrimary: "#000000",
                    textSecondary: "#5296d8",
                    textTertiary: "#000000",
                    disableThemeScrollbar: true,
                  }}
                  config={{
                    header: { disableHeader: true },
                    pdfZoom: {
                      defaultZoom: 0.7,
                    },
                  }}
                />
              ) : (
                <div className="flex flex-col items-center justify-center p-8">
                  <p className="text-gray-500 dark:text-gray-400 mb-4">
                    No Resume Available
                  </p>
                  {(application?.status !== "canceled" ||
                    application?.status !== "rejected") &&
                    renderButton()}
                </div>
              )
            ) : null}
          </dd>
        </div>
      </dl>
    </div>
  );
};

export default ResumeViewer;
