import locationImg from "./images/location.svg";
import PwWhite from "./images/powered-by-white.svg";
import DSposter from "./images/design-poster.png";
import QRS from "./images/qrcodesample.png";
import hmSvg from "./images/house-solid.svg";
import instaGSvg from "./images/instagram-brands-2.svg";
import userImg from "./images/defaultUser.png";
import profileIcon from "./images/profile-icon.jpg";
import bellSolid from "./images/bell-solid.png";
import bellRegular from "./images/bell-regular.png";
import control from "./images/control.svg";
import startChat from "./images/startChat.svg";
import hire from "./images/hire.svg";
import decline from "./images/decline.svg";
import skills from "./images/skills.svg";
import email from "./images/email.svg";
import languages from "./images/languages.svg";
import resume from "./images/resume.svg";
import certifications from "./images/certifications.svg";
import phone from "./images/phone.svg";
import calender from "./images/calender.svg";
import positions from "./images/positions.svg";
import skill from "./images/skill.svg";
import resumeviewer from "./images/resumeviewer.svg";
import instagram from "./images/instagram.svg";
import instagramGray from "./images/instagramGray.svg";
import interview from "./images/interview.svg";
import activity from "./images/activity.svg";
import profile from "./images/profile.svg";
import notifyBell from "./images/notifyBell.svg";
import chatIcon from "./images/chatIcon.svg";
import profileArrow from "./images/profileArrow.svg";
import applicants from "./images/applicants.svg";
import pending from "./images/pending.svg";
import hired from "./images/hired.svg";
import home from "./images/home.svg";
import position from "./images/position.svg";
import locationTag from "./images/locationtag.svg";
import locationTag1 from "./images/locationtag1.svg";
import uploadFile from "./images/upload-file.svg";
import exit from "./images/exit.svg";
import copy from "./images/copy.svg";
import add from "./images/add.svg";
import minus from "./images/minus.svg";
import applicant from "./images/applicant.svg";
import hiredAppIcon from "./images/hireIcon.svg";
import settings from "./images/settings.svg";
import openPosition from "./images/open-position.svg";
import openPositions from "./images/open-positions.svg";
import openPositionsBlack from "./images/open-position-black.svg";
import startChatBlue from "./images/startChatBlue.svg";
import startChatWhite from "./images/startChatWhite.svg";
import locationWhite from "./images/locationWhite.svg";
import ProfileImgArrow from "./images/profileImgArrow.svg";
import applicantBlue from "./images/applicant-blue-icon.svg";
import hiredBlue from "./images/hiredBlue.svg";
import hiredBlack from "./images/hiredBlack.svg";
import profileNew from "./images/profile-new.svg";
import profileNewBlack from "./images/profile-new-black.svg";
import resumeViewerNew from "./images/resumeViewerNew.svg";
import shifts from "./images/shifts.svg";
import shiftsWhite from "./images/shiftsWhite.svg";
import hiredWhite from "./images/hiredWhite.svg";
import applicantWhite from "./images/applicantWhite.svg";
import certificationsWhite from "./images/certificationsWhite.svg";
import addBlue from "./images/addBlue.svg";
import leftArrow from "./images/leftArrow.svg";
import profileWhite from "./images/profileWhiteIcon.svg";
import multipleWhite from "./images/multipleWhiteIcon.svg";
import timer from "./images/timer.svg";
import unlimited from "./images/unlimited.svg";
import plus from "./images/plus.svg";
import heyhireNewIcon from "./images/heyhireNewIcon.svg";
import heyhireNewBlack from "./images/heyhireNewBlack.svg";
import arrowDown from "./images/arrowDown.svg";
import arrowWhiteDown from "./images/arrowWhiteDown.svg";
import heyhireSidebarLogo from "./images/heyhireSidebarLogo.svg";
import heyhireSidebarLogoWhite from "./images/heyhireSidebarWhite.svg";
import intergrations from "./images/intergrations.svg";
import intergrationsWhite from "./images/intergrationsWhite.svg";
import intergrationsGray from "./images/intergrationsGray.svg";
import toast from "./images/toast.png";
import square from "./images/square.png";
import gusto from "./images/gusto.png";
import contact_us from "./images/contact_us.svg";
import shifts7 from "./images/7shift.png";
import adp from "./images/adp_logo.png";
import link from "./images/linkIcon.svg";
import chart from "./images/chart-sample.PNG";
import openJobs from "./images/openJobs.svg";
import barChart from "./images/chart-bar.svg";
import lineChart from "./images/line-chart.svg";
import question from "./images/question.svg";
import languagesGray from "./images/languagesGray.svg";
import certificationsGray from "./images/certificationsGray.svg";
import positionsGray from "./images/positionsGray.svg";
import skillGray from "./images/skillGray.svg";
import resumeViewerGray from "./images/resumeViewerGray.svg";
import interviewGray from "./images/interviewGray.svg";
import activityGray from "./images/activityGray.svg";
import profileImgArrowWhite from "./images/profileImgArrowWhite.svg";
import profileNewWhiteIcon from "./images/profileNewWhiteIcon.svg";
import applicantGray from "./images/applicantGray.svg";
import hiredGray from "./images/hiredGray.svg";
import questionGray from "./images/questionGrayIcon.svg";
import heyhireNewBlueIcon from "./images/heyhireNewIconBlue.svg";
import Shifts7LightLogo from "./images/7shiftsSmallLight.png";
import Shifts7DarkLogo from "./images/7ShiftsLogoDark.png";
import Shifts7LightLarge from "./images/7shiftsLightLarge.png";
import Shifts7DarkLarge from "./images/7shiftsDarkLarge.png";
import Info from "./images/infoIcon.svg";
import InfoGray from "./images/infoIconGray.svg";

export const LineChart = lineChart;
export const BarChart = barChart;
export const OpenJobs = openJobs;
export const ChartSample = chart;
export const LocationImg = locationImg;
export const BellActive = bellSolid;
export const BellInActive = bellRegular;
export const PoweredByWhite = PwWhite;
export const DesignPoster = DSposter;
export const QRcodeSample = QRS;
export const HomeSvg = hmSvg;
export const InstSvg = instaGSvg;
export const defaultUserImage = userImg;
export const defaultUserImage2 = profileIcon;
export const controlSidebar = control;
export const startChatIcon = startChat;
export const startChatBlueIcon = startChatBlue;
export const startChatWhiteIcon = startChatWhite;
export const hireIcon = hire;
export const declineIcon = decline;
export const skillsIcon = skills;
export const emailIcon = email;
export const resumeIcon = resume;
export const languagesIcon = languages;
export const languagesGrayIcon = languagesGray;
export const certificationsIcon = certifications;
export const phoneIcon = phone;
export const calenderIcon = calender;
export const positionsIcon = positions;
export const positionsGrayIcon = positionsGray;
export const skillIcon = skill;
export const skillGrayIcon = skillGray;
export const resumeViewerIcon = resumeviewer;
export const instagramIcon = instagram;
export const instagramGrayIcon = instagramGray;
export const interviewIcon = interview;
export const interviewGrayIcon = interviewGray;
export const activityIcon = activity;
export const activityGrayIcon = activityGray;
export const bioIcon = profile;
export const notifyBellIcon = notifyBell;
export const chatListIcon = chatIcon;
export const profileArrowIcon = profileArrow;
export const applicantsIcon = applicants;
export const pendingIcon = pending;
export const hiredIcon = hired;
export const homeIcon = home;
export const positionIcon = position;
export const locationTagIcon = locationTag;
export const locationTag1Icon = locationTag1;
export const uploadFileIcon = uploadFile;
export const exitIcon = exit;
export const copyIcon = copy;
export const addIcon = add;
export const minusIcon = minus;
export const applicantIcon = applicant;
export const applicantGrayIcon = applicantGray;
export const hireApplicantIcon = hiredAppIcon;
export const settingsIcon = settings;
export const openPositionIcon = openPosition;
export const locationWhiteIcon = locationWhite;
export const ProfileImgArrowIcon = ProfileImgArrow;
export const ProfileImgArrowWhiteIcon = profileImgArrowWhite;
export const openPositionsIcon = openPositions;
export const openPositionsBlackIcon = openPositionsBlack;
export const applicantBlueIcon = applicantBlue;
export const hiredBlueIcon = hiredBlue;
export const hiredBlackIcon = hiredBlack;
export const hiredGrayIcon = hiredGray;
export const profileNewIcon = profileNew;
export const profileNewBlackIcon = profileNewBlack;
export const profileNewWhite = profileNewWhiteIcon;
export const resumeViewerPDFIcon = resumeViewerNew;
export const resumeViewerPdfGrayIcon = resumeViewerGray;
export const shiftsIcon = shifts;
export const shiftsWhiteIcon = shiftsWhite;
export const hiredWhiteIcon = hiredWhite;
export const applicantWhiteIcon = applicantWhite;
export const certificationsWhiteIcon = certificationsWhite;
export const certificationsGrayIcon = certificationsGray;
export const addBlueIcon = addBlue;
export const leftArrowImage = leftArrow;
export const profileWhiteIcon = profileWhite;
export const multipleWhiteIcon = multipleWhite;
export const timerIcon = timer;
export const unlimitedIcon = unlimited;
export const plusIcon = plus;
export const heyhireIcon = heyhireNewIcon;
export const heyhireBlueIcon = heyhireNewBlueIcon;
export const heyhireBlackIcon = heyhireNewBlack;
export const arrowDownIcon = arrowDown;
export const arrowDownWhiteIcon = arrowWhiteDown;
export const heyhireSidebar = heyhireSidebarLogo;
export const heyhireSidebarWhite = heyhireSidebarLogoWhite;
export const intergrationsIcon = intergrations;
export const intergrationsWhiteIcon = intergrationsWhite;
export const intergrationsGrayIcon = intergrationsGray;
export const adpIcon = adp;
export const toastIcon = toast;
export const squareIcon = square;
export const gustoIcon = gusto;
export const shifts7Icon = shifts7;
export const contactUsIcon = contact_us;
export const linkIcon = link;
export const questionIcon = question;
export const questionIconGray = questionGray;
export const shifts7LightIcon = Shifts7LightLogo;
export const shifts7DarkIcon = Shifts7DarkLogo;
export const Shifts7LightLargeIcon = Shifts7LightLarge;
export const Shifts7DarkLargeIcon = Shifts7DarkLarge;
export const InfoIcon = Info;
export const InfoIconGray = InfoGray;
