import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export const ConfirmDialog = ({
  show,
  onClose,
  onConfirm,
  isLoading,
  applicant,
}) => {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[90]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-black dark:bg-opacity-70 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-[#1F1F1F] shadow-xl transition-all sm:max-w-lg w-full">
                <div className="p-6">
                  <div className="text-center">
                    <Dialog.Title className="text-2xl font-semibold text-gray-900 dark:text-gray-300 mb-4">
                      Share Schedule Configuration
                    </Dialog.Title>
                    <div className="mt-4">
                      <p className="text-gray-600 dark:text-gray-400">
                        Are you sure you want to share this scheduling
                        configuration with{" "}
                        <span className="font-medium">
                          {applicant?.first_name} {applicant?.last_name}
                        </span>
                        ? They will be able to book a time according to your
                        availability settings.
                      </p>
                    </div>
                  </div>

                  <div className="mt-8 flex justify-end gap-3">
                    <button
                      type="button"
                      className="px-4 py-2 text-sm font-medium text-gray-400 dark:text-gray-300 bg-white dark:bg-darkGray border border-gray-400 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="px-4 py-2 text-sm font-medium text-white bg-primary-500 rounded-lg hover:bg-blue-700 focus:outline-none"
                      onClick={onConfirm}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <div className="flex items-center">
                          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                          Sharing...
                        </div>
                      ) : (
                        "Share Schedule"
                      )}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
