import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import { selectSelectedLocation, selectUser } from "../../../store/authSlice";
import { toast } from "react-toastify";
import Scheduler from "../../../api/Scheduler";
import { useNavigate } from "react-router-dom";
import { ShareSuccessModal } from "../../../components/molecules/Scheduler/ShareSuccessModal";
import { ConfirmDialog } from "../../../components/molecules/Scheduler/ConfirmDialog";
import EmptyStateContent from "../../../components/molecules/Scheduler/EmptyStateContent";
import ConfigSelection from "../../../components/molecules/Scheduler/ConfigSelection";
import ScheduleMeeting from "../../../components/molecules/Scheduler/ScheduleMeeting";
import ScheduledInterviewDetail from "../../../components/molecules/Scheduler/ScheduledInterviewDetail";
import NylasSchedulingComponent from "../../../components/molecules/Scheduler/NylasSchedulingComponent";
import { encodeIds } from "../../../utils";

const SchedulingOptionsModal = ({ open, setOpen, applicant, positionId }) => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const selectedLocation = useSelector(selectSelectedLocation);
  const [showNylasScheduler, setShowNylasScheduler] = useState(false);
  const [schedulingMode, setSchedulingMode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [configs, setConfigs] = useState([]);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [showConfigSelection, setShowConfigSelection] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [configToConfirm, setConfigToConfirm] = useState(null);
  const [isBookingLoading, setIsBookingLoading] = useState(false);
  const [showShareSuccess, setShowShareSuccess] = useState(false);
  const [schedulingLink, setSchedulingLink] = useState("");
  const [interviewDetails, setInterviewDetails] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setShowNylasScheduler(false);
    setSchedulingMode(null);
    setShowConfigSelection(false);
    setSelectedConfig(null);
  };

  const handleShareSchedule = () => {
    setSchedulingMode("share");
    setShowConfigSelection(true);
  };

  const handleSetDateTime = () => {
    setSchedulingMode("set");
    setShowConfigSelection(true);
  };

  const handleScheduleEditor = () => {
    navigate("/scheduler-editor");
  };

  const handleConfigSelect = (configId) => {
    if (schedulingMode === "share") {
      setConfigToConfirm(configId);
      setShowConfirmDialog(true);
    } else {
      setSelectedConfig(configId);
      setShowNylasScheduler(true);
      setShowConfigSelection(false);
    }
  };

  const handleConfirmShare = async () => {
    setIsBookingLoading(true);
    try {
      const basePayload = {
        user_id: user?.id,
        company_id: user?.company_id,
        location_id: selectedLocation?.id,
        job_seeker_id: applicant?.id,
        job_position_id: positionId,
        schedule_type: "share",
        schedule_config_id: configToConfirm,
        booking_id: "",
        event_id: "",
        status: "",
        timeStamp: "",
        start_time: "",
        end_time: "",
        organizer: "",
        guest: "",
        additional_guests: "",
      };
      await Scheduler.storeBookingData(basePayload);
      const shareableLink = `https://www.heyhire.app/schedule/${encodeIds(
        positionId
      )}/${configToConfirm}`;
      setSchedulingLink(shareableLink);
      setShowConfirmDialog(false);
      setOpen(false);
      setShowShareSuccess(true);
    } catch (error) {
      toast.error("Failed to share scheduling link.");
      console.error("Booking error:", error);
    } finally {
      setIsBookingLoading(false);
    }
  };

  const handleMainModalClose = () => {
    if (!showConfirmDialog && !showShareSuccess) {
      handleClose();
    }
  };

  const handleBooking = async (type, event, configId) => {
    try {
      const payload = {
        user_id: user?.id,
        company_id: user?.company_id,
        location_id: selectedLocation?.id,
        job_seeker_id: applicant?.id,
        job_position_id: positionId,
        schedule_type: type,
        schedule_config_id: selectedConfig || configId,
        booking_id: event?.detail?.data?.booking_id,
        event_id: event?.detail?.data?.booking_id,
        status: event?.detail?.data?.status,
        timeStamp: event?.timeStamp,
        start_time: event?.detail?.data?.start_time,
        end_time: event?.detail?.data?.end_time,
        organizer: JSON.stringify(event?.detail?.data?.organizer),
        guest: JSON.stringify(event?.detail?.data?.guest),
        additional_guests: JSON.stringify(
          event?.detail?.data?.additional_guests
        ),
      };
      await Scheduler.storeBookingData(payload);
      toast.success("Interview time has been set successfully!");
    } catch (error) {
      toast.error("Failed to set interview time.");
      console.error("Booking error:", error);
    }
  };

  const cancelBooking = async (type, event, configId) => {
    try {
      const payload = {
        user_id: user?.id,
        company_id: user?.company_id,
        location_id: selectedLocation?.id,
        job_seeker_id: applicant?.id,
        job_position_id: positionId,
        schedule_type: type,
        schedule_config_id: selectedConfig || configId,
        booking_id: event?.detail?.bookingId,
        status: event?.detail?.action,
        reason: event?.detail?.reason,
        timeStamp: event?.timeStamp,
      };
      await Scheduler.cancelBookingData(payload);
      toast.success("Interview cancelled!");
    } catch (error) {
      toast.error("Failed to cancel interview.");
      console.error("Booking error:", error);
    }
  };

  const getInterviewDetails = async () => {
    try {
      setLoading(true);
      const response = await Scheduler.getInterviewDetails(
        user?.id,
        applicant?.id,
        positionId
      );
      if (
        response?.data?.data[0] &&
        response?.data?.data[0]?.status !== "cancel"
      ) {
        setInterviewDetails(response.data.data[0]);
      } else {
        getConfigDetails();
      }
    } catch (error) {
      toast.error("Failed to fetch interview details");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getConfigDetails = async () => {
    try {
      setLoading(true);
      const response = await Scheduler.getConfigDetails(user?.id);
      setConfigs(response?.data?.data || []);
    } catch (error) {
      toast.error("Failed to fetch interview notes");
      console.error(error);
      setConfigs([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open && user && applicant) {
      setInterviewDetails(null);
      getInterviewDetails();
    }
  }, [open, user, applicant]);

  const renderContent = () => {
    if (!open) return null;

    if (loading) {
      return (
        <div className="flex items-center justify-center p-8">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
        </div>
      );
    }

    if (interviewDetails) {
      return (
        <ScheduledInterviewDetail
          interviewData={interviewDetails}
          applicant={applicant}
          onBooking={handleBooking}
          onCancelBooking={cancelBooking}
          onBack={handleClose}
          configId={interviewDetails?.schedule_config_id}
        />
      );
    }

    if (!configs) return null;

    if (configs.length === 0) {
      return <EmptyStateContent onScheduleEditor={handleScheduleEditor} />;
    }

    if (showConfigSelection) {
      return (
        <ConfigSelection
          configs={configs}
          onConfigSelect={handleConfigSelect}
          onScheduleEditor={handleScheduleEditor}
          onBack={() => {
            setShowConfigSelection(false);
            setSchedulingMode(null);
          }}
        />
      );
    }

    return (
      <ScheduleMeeting
        onShareSchedule={handleShareSchedule}
        onSetDateTime={handleSetDateTime}
      />
    );
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={handleMainModalClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 dark:bg-black dark:bg-opacity-70 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`relative transform overflow-hidden rounded-lg bg-white dark:bg-[#1F1F1F] shadow-xl transition-all`}
                >
                  {showNylasScheduler ? (
                    <NylasSchedulingComponent
                      selectedConfig={selectedConfig}
                      schedulingMode={schedulingMode}
                      applicant={applicant}
                      onBooking={handleBooking}
                      onCancelBooking={cancelBooking}
                      onBack={() => {
                        setShowNylasScheduler(false);
                        setShowConfigSelection(true);
                      }}
                      onClose={handleClose}
                      configId={interviewDetails?.schedule_config_id}
                    />
                  ) : (
                    renderContent()
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <ConfirmDialog
        show={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={handleConfirmShare}
        isLoading={isBookingLoading}
        applicant={applicant}
      />

      <ShareSuccessModal
        open={showShareSuccess}
        setOpen={setShowShareSuccess}
        schedulingLink={schedulingLink}
      />
    </>
  );
};

export default SchedulingOptionsModal;
