import React from "react";
import { Dialog } from "@headlessui/react";

const ConfigSelection = ({
  configs,
  onConfigSelect,
  onScheduleEditor,
  onBack,
}) => {
  return (
    <div className="p-6">
      <div className="flex justify-center mb-2">
        <div className="w-16 h-16 bg-blue-100 dark:bg-gray-600 dark:text-white rounded-full flex items-center justify-center">
          <svg
            viewBox="0 0 24 24"
            className="w-8 h-8"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </div>
      </div>

      <div className="mb-8 text-center">
        <Dialog.Title className="text-2xl font-semibold text-gray-900 dark:text-gray-300 mb-2">
          Select Schedule Configuration
        </Dialog.Title>
        <p className="text-gray-600 dark:text-gray-500">
          Choose a scheduling configuration to proceed
        </p>
      </div>

      <div className="space-y-4 max-h-[600px] overflow-y-auto">
        {configs?.map((config) => {
          const eventBooking = JSON.parse(config?.event_booking);
          const availability = JSON.parse(config?.availability);

          return (
            <button
              key={config.id}
              onClick={() => onConfigSelect(config?.config_id)}
              className="w-full p-3 text-left border-2 border-warmgray-100 dark:border-darkGray rounded-xl hover:border-blue-500 hover:bg-blue-50 dark:hover:bg-gray-600 transition-all group"
            >
              <div className="flex flex-col space-y-3">
                <div className="flex justify-between items-start">
                  <h3 className="text-lg font-semibold dark:text-gray-300">
                    {eventBooking.title}
                  </h3>
                  <div className="flex items-center bg-blue-50 dark:bg-darkGray px-3 py-1 rounded-full ml-4">
                    <svg
                      viewBox="0 0 24 24"
                      className="h-4 w-4 mr-1 text-blue-500 dark:text-blue-500"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <circle cx="12" cy="12" r="10" />
                      <path strokeLinecap="round" d="M12 6v6l4 2" />
                    </svg>
                    <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
                      {availability.duration_minutes} min
                    </span>
                  </div>
                </div>

                {eventBooking.description && (
                  <p className="text-sm text-gray-600 dark:text-gray-500">
                    {eventBooking.description}
                  </p>
                )}

                <div className="flex flex-col">
                  <div className="flex items-center text-sm text-gray-600 dark:text-gray-500">
                    {eventBooking.conferencing?.provider ? (
                      <svg
                        viewBox="0 0 24 24"
                        className="h-5 w-5 text-gray-500 dark:text-gray-500"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14" />
                        <rect
                          x="3"
                          y="6"
                          width="12"
                          height="12"
                          rx="2"
                          ry="2"
                        />
                      </svg>
                    ) : (
                      <svg
                        viewBox="0 0 24 24"
                        className="h-4 w-4 text-gray-500 dark:text-gray-500"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <circle cx="12" cy="8" r="4" />
                        <path d="M18 21v-2a4 4 0 00-4-4H10a4 4 0 00-4 4v2" />
                      </svg>
                    )}
                    <span className="ml-2">
                      {eventBooking.conferencing?.provider || "In person"}
                    </span>
                  </div>

                  {eventBooking.location && (
                    <div className="flex items-center space-x-2 mt-1 text-sm text-gray-500 dark:text-gray-500">
                      <svg
                        viewBox="0 0 24 24"
                        className="h-4 w-4 text-gray-500 dark:text-gray-500"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path d="M12 21s-8-4.5-8-11a8 8 0 1116 0c0 6.5-8 11-8 11z" />
                        <circle cx="12" cy="10" r="3" />
                      </svg>
                      <span>{eventBooking.location}</span>
                    </div>
                  )}
                </div>
              </div>
            </button>
          );
        })}
      </div>

      <div className="mt-6 space-y-2">
        <button
          onClick={onScheduleEditor}
          className="w-full py-3 px-4 bg-primary-500 dark:bg-darkGray hover:bg-primary-400 text-white rounded-lg transition-colors flex items-center justify-center space-x-2"
        >
          <span>View Scheduler Editor</span>
        </button>

        <button
          onClick={onBack}
          className="w-full px-4 py-2 text-sm text-gray-600 dark:text-gray-500 hover:text-gray-900 dark:hover:text-gray-200 border border-gray-200 dark:border-darkGray rounded-lg"
        >
          Back to options
        </button>
      </div>
    </div>
  );
};

export default ConfigSelection;
