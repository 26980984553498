import axios from "./api";

const base = "/business/job-position";
const goBase = "/business/job-position-go";

const JobPosition = {
  getAll() {
    return axios.get(base);
  },
  getById(id) {
    return axios.get(base + `/${id}`);
  },
  add(jobPosition) {
    return axios.post(base, jobPosition);
  },
  addGoPosition(jobPosition) {
    return axios.post(goBase, jobPosition);
  },
  update(id, jobPosition) {
    return axios.put(`${base}/${id}`, jobPosition);
  },
  delete(id) {
    return axios.delete(base + `/${id}`);
  },
  archive(id) {
    return axios.patch(base + `-archive/${id}`);
  },
  getHiredEmployees(job_position_id) {
    return axios.get(`/business/hired/position/${job_position_id}`);
  },
  addInterviewNotes(data) {
    return axios.post(base + `/notes`, data);
  },
  deleteInterviewNote(id) {
    return axios.delete(base + `/deleteNotes/${id}`);
  },
  collectInterviewNotes(data) {
    return axios.post(base + `/interview-notes`, data);
  },
  checkJobCreated() {
    return axios.get(`/business/job-position-created`);
  },
};

export default JobPosition;
