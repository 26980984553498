import React from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const ApplicationLoadingSkeleton = ({ isMobileView = false }) => {
  const segments = [
    { label: "Applicants" },
    { label: "Interviews" },
    { label: "Hired" },
    { label: "Declined" },
  ];

  return (
    <div className="w-full animate-pulse space-y-6">
      {/* Job Position Filter Bar and Segments */}
      <div
        className={`${
          isMobileView ? "hidden" : "flex"
        } items-center w-full rounded-xl border-2 border-warmgray-200 dark:border-darkGray bg-gray-300 dark:bg-[#363739] whitespace-nowrap overflow-x-auto`}
      >
        {/* Segments */}
        {segments.map((segment, index) => (
          <div
            key={segment.label}
            className={`flex flex-1 items-center justify-center py-2 px-4 bg-gray-300 dark:bg-[#363739] text-gray-900 dark:text-gray-300 text-sm
              ${index === 3 ? "border-none" : "border-r border-warmgray-400"}
              ${index === 0 && "border-l border-warmgray-400"}`}
          >
            <span>{segment.label}</span>
            <div className="ml-1 w-6 h-4 bg-gray-300 dark:bg-[#363739] rounded" />
          </div>
        ))}
      </div>

      {/* Application Details Card */}
      <div
        className={`rounded-xl border-2 border-gray-300 dark:border-darkGray shadow-md bg-white dark:bg-[#242526]`}
      >
        <div
          className={`flex items-center justify-between ${
            isMobileView ? "p-2" : "px-2 py-6"
          }`}
        >
          {/* Previous Button */}
          <div className="p-2 rounded-full">
            <FiChevronLeft className="text-gray-300 dark:text-gray-600 w-6 h-6" />
          </div>

          <div
            className={`flex ${
              isMobileView ? "flex-col md:flex-row" : "flex-row"
            } flex-1 items-center mx-2`}
          >
            <div
              className={`flex ${
                isMobileView
                  ? "flex-col md:flex-row items-center text-center md:text-left"
                  : "flex-row items-center"
              } flex-1`}
            >
              {/* Avatar Skeleton */}
              <div
                className={`${
                  isMobileView ? "w-32 h-32 md:w-16 md:h-16" : "w-16 h-16"
                } flex-shrink-0`}
              >
                <div className="w-full h-full bg-gray-300 dark:bg-[#363739] rounded-full" />
              </div>

              {/* Details Skeleton */}
              <div
                className={`${
                  isMobileView ? "mt-4 md:mt-0 md:ml-4" : "ml-4"
                } space-y-2`}
              >
                <div className="h-6 w-48 bg-gray-300 dark:bg-[#363739] rounded" />
                <div className="h-4 w-32 bg-gray-300 dark:bg-[#363739] rounded" />
                <div className="h-4 w-40 bg-gray-300 dark:bg-[#363739] rounded" />
                <div className="h-4 w-36 bg-gray-300 dark:bg-[#363739] rounded" />
              </div>
            </div>

            {/* Action Buttons Skeleton */}
            <div
              className={`${
                isMobileView
                  ? "w-full md:w-auto flex justify-center md:justify-end mt-3 md:mt-0"
                  : "ml-auto"
              }`}
            >
              <div className="flex space-x-2">
                {[1, 2, 3, 4].map((i) => (
                  <div
                    key={i}
                    className="w-10 h-10 bg-gray-300 dark:bg-[#363739] rounded-full"
                  />
                ))}
              </div>
            </div>
          </div>

          {/* Next Button */}
          <div className="p-2 rounded-full">
            <FiChevronRight className="text-gray-300 dark:text-gray-600 w-6 h-6" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationLoadingSkeleton;
