import React, { useState } from "react";
import dayjs from "dayjs";
import NylasSchedulingComponent from "./NylasSchedulingComponent";
import NylasCancelBooking from "./NylasCancelBooking";
import { encodeIds } from "../../../utils";

const ScheduledInterviewDetail = ({
  interviewData,
  applicant,
  onBooking,
  onCancelBooking,
  onBack,
  configId,
}) => {
  const [showScheduling, setShowScheduling] = useState(false);
  const [showCancelCard, setShowCancelCard] = useState(false);
  const [copied, setCopied] = useState(false);
  const {
    schedule_type,
    status,
    is_interview_complete,
    event_id,
    booking_id,
    organizer,
    start_time,
    end_time,
    config_details,
  } = interviewData;
  const parsedEventBooking = JSON.parse(config_details?.event_booking || "{}");
  const shareableLink = `https://www.heyhire.app/schedule/${encodeIds(
    interviewData?.job_position_id
  )}/${config_details.config_id}`;

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareableLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy link:", err);
    }
  };

  const formatDateTime = (timestamp) =>
    dayjs.unix(timestamp).format("MMM DD, YYYY hh:mm A");

  if (showScheduling) {
    return (
      <NylasSchedulingComponent
        selectedConfig={config_details.config_id}
        isRescheduling={true}
        applicant={applicant}
        eventId={event_id}
        onBack={() => setShowScheduling(false)}
        onBooking={onBooking}
        onCancelBooking={onCancelBooking}
        configId={configId}
      />
    );
  }

  if (showCancelCard) {
    return (
      <NylasCancelBooking
        eventId={event_id}
        bookingId={booking_id}
        organizer={organizer}
        startTime={start_time}
        endTime={end_time}
        configSettings={config_details}
        eventInfo={parsedEventBooking}
        onBack={() => setShowCancelCard(false)}
        onCancelSubmit={onCancelBooking}
        onGoBack={() => {
          setShowCancelCard(false);
        }}
        onConfirmClose={() => {
          setShowCancelCard(false);
          onBack();
        }}
        configId={configId}
      />
    );
  }

  if (schedule_type === "set" && status === "booked") {
    return (
      <div className="w-full max-w-2xl p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold dark:text-gray-200">
            {is_interview_complete
              ? "Interview Completed"
              : "Scheduled Interview"}
          </h2>
          <button
            onClick={onBack}
            className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="bg-white dark:bg-gray-600 rounded-lg shadow-sm border border-warmgray-300 dark:border-darkGray">
          <div className="p-4 border-b border-warmgray-300 dark:border-gray-400">
            <h3 className="text-lg font-semibold dark:text-gray-200">
              {parsedEventBooking.title}
            </h3>
          </div>
          <div className="p-4 space-y-4">
            <div className="flex items-start space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mt-1 text-gray-500 dark:text-gray-300"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <div>
                <p className="font-medium dark:text-gray-200">Time</p>
                <p className="text-gray-600 dark:text-gray-500">
                  {formatDateTime(start_time)} - {formatDateTime(end_time)}
                </p>
              </div>
            </div>

            <div className="flex flex-col pb-4">
              <div className="flex items-center text-sm text-gray-600 dark:text-gray-500">
                {parsedEventBooking?.conferencing?.provider ? (
                  <svg
                    viewBox="0 0 24 24"
                    className="h-5 w-5 text-gray-500 dark:text-gray-500"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14" />
                    <rect x="3" y="6" width="12" height="12" rx="2" ry="2" />
                  </svg>
                ) : (
                  <svg
                    viewBox="0 0 24 24"
                    className="h-4 w-4 text-gray-500 dark:text-gray-500"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <circle cx="12" cy="8" r="4" />
                    <path d="M18 21v-2a4 4 0 00-4-4H10a4 4 0 00-4 4v2" />
                  </svg>
                )}
                <span className="ml-2">
                  {parsedEventBooking?.conferencing?.provider || "In person"}
                </span>
              </div>

              {parsedEventBooking?.location && (
                <div className="flex items-center space-x-2 mt-1 text-sm text-gray-500 dark:text-gray-500">
                  <svg
                    viewBox="0 0 24 24"
                    className="h-4 w-4 text-gray-500 dark:text-gray-500"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path d="M12 21s-8-4.5-8-11a8 8 0 1116 0c0 6.5-8 11-8 11z" />
                    <circle cx="12" cy="10" r="3" />
                  </svg>
                  <span>{parsedEventBooking?.location}</span>
                </div>
              )}
            </div>

            {!is_interview_complete && (
              <div className="flex justify-end gap-4 mt-6">
                {/* <button
                  onClick={() => setShowScheduling(true)}
                  className="px-4 py-2 bg-primary-500 hover:bg-primary-400 text-white rounded-md transition-colors"
                >
                  Reschedule Interview
                </button> */}
                <button
                  onClick={() => setShowCancelCard(true)}
                  className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-md transition-colors"
                >
                  Cancel Interview
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  // Default case for share type
  return (
    <div className="w-full max-w-2xl p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold dark:text-gray-200">
          Scheduled Interview
        </h2>
        <button
          onClick={onBack}
          className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div className="bg-white dark:bg-gray-600 rounded-lg shadow-sm border border-warmgray-300 dark:border-darkGray">
        <div className="p-4 border-b border-warmgray-300 dark:border-gray-400">
          <h3 className="text-lg font-semibold dark:text-gray-200">
            {parsedEventBooking.title}
          </h3>
        </div>
        <div className="p-4 space-y-4">
          <div className="flex items-start space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mt-1 text-gray-500 dark:text-gray-300"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <div>
              <p className="font-medium dark:text-gray-200">Location</p>
              <p className="text-gray-600 dark:text-gray-500">
                {parsedEventBooking.location}
              </p>
            </div>
          </div>

          <div className="flex items-start space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mt-1 text-gray-500 dark:text-gray-300"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <div>
              <p className="font-medium dark:text-gray-200">Duration</p>
              <p className="text-gray-600 dark:text-gray-500">
                {JSON.parse(config_details.availability).duration_minutes}{" "}
                minutes
              </p>
            </div>
          </div>

          <div className="mt-4">
            <p className="font-medium dark:text-gray-200 mb-2">Description</p>
            <p className="text-gray-600 dark:text-gray-500">
              {parsedEventBooking.description}
            </p>
          </div>

          {interviewData.schedule_type === "share" &&
            !interviewData.booking_id && (
              <div className="mt-4 space-y-4">
                <div className="p-4 bg-yellow-50 dark:bg-yellow-900/20 rounded-lg">
                  <p className="text-yellow-800 dark:text-yellow-200">
                    The scheduling link has been shared but the interview time
                    hasn't been confirmed yet.
                  </p>
                </div>

                <div className="flex items-center gap-2 p-4 dark:p-0 bg-gray-50 dark:bg-transparent rounded-lg">
                  <input
                    type="text"
                    value={shareableLink}
                    readOnly
                    className="flex-1 p-2 bg-white dark:bg-darkGray border border-gray-300 dark:border-gray-600 rounded text-sm text-gray-600 dark:text-gray-300"
                  />
                  <button
                    onClick={handleCopyLink}
                    className="flex items-center gap-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md transition-colors"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                      />
                    </svg>
                    {copied ? "Copied!" : "Copy"}
                  </button>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ScheduledInterviewDetail;
