import React, { useState } from "react";
import { NylasScheduling } from "@nylas/react";
import { useSelector } from "react-redux";
import { selectSelectedLocation } from "../../../store/authSlice";

const NylasSchedulingComponent = ({
  selectedConfig,
  schedulingMode,
  applicant,
  onBooking,
  onCancelBooking,
  onBack,
  onClose,
  isRescheduling = false,
  eventId = null,
  configId,
}) => {
  const [showCloseButton, setShowCloseButton] = useState(false);
  const selectedLocation = useSelector(selectSelectedLocation);
  return (
    <div className="w-full flex flex-col">
      <div className="flex justify-between items-center p-4 border-b dark:border-gray-700">
        <h2 className="text-lg font-semibold dark:text-gray-300">
          {isRescheduling
            ? "Reschedule Interview"
            : schedulingMode === "set" &&
              `Please select a day & time to interview with ${applicant?.first_name} ${applicant?.last_name} at ${selectedLocation?.name}`}
        </h2>
        <div className="flex items-center gap-2">
          {showCloseButton ? (
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          ) : (
            <button
              onClick={onBack}
              className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
      <div className="w-full overflow-y-auto">
        <NylasScheduling
          configurationId={selectedConfig}
          eventId={eventId}
          schedulerApiUrl="https://api.us.nylas.com/v3"
          className="w-full h-full scale-100"
          scheduling_type={schedulingMode === "set" && "set"}
          eventOverrides={{
            bookedEventInfo: async (event) => {
              onBooking("set", event, configId);
              setShowCloseButton(true);
            },
            cancelBookingFormSubmitted: async (event) => {
              onCancelBooking("set", event, configId);
              setShowCloseButton(true);
            },
          }}
          bookingInfo={
            applicant && {
              primaryParticipant: {
                name: `${applicant?.first_name} ${applicant?.last_name}`,
                email: applicant?.email,
              },
            }
          }
          localization={{
            en: {
              bookingCancelledMessage:
                "A cancellation email has been sent to the applicant and",
            },
          }}
          enableUserFeedback={false}
        />
      </div>
    </div>
  );
};

export default NylasSchedulingComponent;
