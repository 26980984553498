import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LinkIcon, PencilIcon } from "@heroicons/react/24/outline";
import { encodeIds } from "../../../utils";

export const PositionHeader = ({ selectedPosition }) => {
  const navigate = useNavigate();
  const availabilityList = [
    { value: "full-time", label: "Full Time" },
    { value: "part-time", label: "Part Time" },
  ];

  const handleCopyLink = async () => {
    const positionUrl = `${
      window.location.origin
    }/business/applicant/${encodeIds(selectedPosition?.value?.id)}`;
    try {
      await navigator.clipboard.writeText(positionUrl);
      toast.success("Link copied!");
    } catch (err) {
      console.error("Failed to copy link:", err);
      toast.error("Failed to copy link");
    }
  };

  const parseAvailability = () => {
    try {
      const parsed =
        typeof selectedPosition?.value?.availability === "string"
          ? JSON.parse(selectedPosition?.value?.availability)
          : selectedPosition?.value?.availability;

      return Array.isArray(parsed)
        ? parsed
            .map((value) =>
              availabilityList.find((item) => item.value === value)
            )
            .filter(Boolean)
        : [availabilityList.find((item) => item.value === parsed)].filter(
            Boolean
          );
    } catch {
      return [
        availabilityList.find(
          (item) => item.value === selectedPosition?.value?.availability
        ),
      ].filter(Boolean);
    }
  };

  return (
    <div className="bg-white dark:bg-[#242526] rounded-xl shadow-sm mb-2 border border-primary-200 dark:border-gray-400 p-4">
      <div className="flex justify-between gap-4">
        {/* Position Information */}
        <div className="space-y-2">
          <h2 className="text-lg font-bold text-primary-500 dark:text-gray-300 line-clamp-2">
            {selectedPosition?.label || "Select a Position"}
          </h2>

          <div className="flex flex-col gap-1">
            {selectedPosition?.value?.availability && (
              <div className="inline-flex">
                {parseAvailability().map((item, index) => (
                  <span
                    key={item.value}
                    className="text-xs font-bold text-primary-500 dark:text-gray-300  rounded-full"
                  >
                    {item.label}
                  </span>
                ))}
              </div>
            )}

            <span className="text-xs font-bold text-primary-500 dark:text-gray-300 rounded-full">
              {selectedPosition?.value?.hiring_status === 0
                ? "Accepting Applications"
                : "Immediately Hiring"}
            </span>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex gap-1.5">
          <button
            onClick={handleCopyLink}
            className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-primary-100 hover:bg-primary-200 dark:bg-gray-700 dark:hover:bg-gray-400 transition-colors"
            aria-label="Copy link"
            title="Copy link"
          >
            <LinkIcon className="h-4 w-4" />
          </button>

          <button
            onClick={() =>
              navigate(
                "/job-positions/edit/" + encodeIds(selectedPosition?.value?.id)
              )
            }
            title="Edit Position"
            className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-primary-100 hover:bg-primary-200 dark:bg-gray-700 dark:hover:bg-gray-400 transition-colors"
          >
            <PencilIcon className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PositionHeader;
